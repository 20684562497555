// Represent initial data which fetched from db for current operation station
export interface DataStation {
  id: Array<any>;
  id_stazione: number;
  id_cliente: string;
  id_rete: string;
  nome: string;
  note: string;
  data_inserimento_nota: any;
  start_photo: any;
  date_start: any;
  request_id: null;
  photos: any;
  send_operation: boolean;
  operation_type: any;
  requests: Array<any>;
  type_calibration: Array<number>;
  type_tools: Array<number>;
  type_tasks: Array<number>;
  step_tasks: any;
  step_finish: any;
  step_calibrations: any;
  documents: Array<any>;
  total_photos: number;
}
export interface StationState {
  GPSActive: boolean;
  GPSCoord: any;
  notificationFailed: boolean;
  stationsList: any;
  networksList: any;
  requestsList: any;
  data: DataStation;
}

export const initialState: StationState = {
  GPSActive: false,
  GPSCoord: null,
  notificationFailed: false,
  stationsList: null,
  networksList: null,
  requestsList: null,
  data: {
    id: null,
    id_stazione: null,
    id_cliente: null,
    id_rete: null,
    nome: null,
    note: null,
    data_inserimento_nota: null,
    start_photo: null,
    photos: {},
    date_start: null,
    request_id: null,
    send_operation: false,
    operation_type: 'common',
    requests: null,
    type_calibration: null,
    type_tools: null,
    type_tasks: null,
    step_tasks: null,
    step_finish: null,
    step_calibrations: null,
    documents: null,
    total_photos: 0 
  }
};
