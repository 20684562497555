import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';
import { ApiService } from './api.service';
import { DbService } from './db.service';
import * as StackTrace from 'stacktrace-js'
@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(private injector: Injector, private api: ApiService, private db: DbService) { }

  handleError(error) {
    const router = this.injector.get(Router)
    const store = this.injector.get(Store)
    if (error instanceof HttpErrorResponse) {
      // Server Error
    } else {
      // Client Error
      console.error(error)
      const message = {
        status: 'GlobalError',
        errMsg: error.message,
        path: router.url,
        errorTime: new Date()
      }
      from(StackTrace.fromError(error)).pipe(
        take(1),
        concatMap(stack =>
          from(store.select('auth')).pipe(
            take(1),
            concatMap(user =>
              from(store.select('system')).pipe(
                take(1),
                concatMap((state) => {
                  message['operator'] = user.credentials.username
                  if (stack.length > 0)
                    message['stack'] = stack[0]
                  if (state.online) {
                    return this.api.sendError(JSON.stringify(message))
                  } else {
                    return this.db.addPendingErrors(JSON.stringify(message))
                  }
                })
              )
            )
          )
        )
      ).subscribe()
    }
  }
}