import { Action } from '@ngrx/store';

export const START_NAVIGATION_TO_STEP = '[UI button] Start navigation to next step';
export const SET_INDEX = '[Step] Set index successfully';
export const SET_NAME = '[Step] Set name successfully';
export const SET_EXTRA_STEP_LIST = '[Step] Set extra type step list';
export const SET_COMMON_STEP_LIST = '[Step] Set common type step list';

/** */
export const START_OPERATION = '[Station Page] Start operation for selected station';
export const FINISH_OPERATION = '[Finish Page] Finish operation';

export const OPERATION_NEW = '[IndexedDB] Started operation is new';
export const OPERATION_EXIST = '[IndexedDB] Started operation already exist';

export const GET_IMAGE_FROM_BLOB_FAIL = '[API] Get image from blob: link failed';

export const COMPLETE_NOTIFICATION = '[IndexedDB] Insert notification status to DB';

export const OPERATION_OVERWRITE = '[IndexedDB] Overwrite operation with Started operation ID';
export const OPERATION_ADDED_SUCCESSFULY = '[IndexedDB] Operation inserted to operation list successfuly';
export const OPERATION_ADDED_FAIL = '[IndexedDB] Operation inserted to operation list failed';
export const FINISH_OPERATION_SUCCESS = '[IndexedDB] Operation finished added to DB successfuly';

export const ADD_PENDING_OPERATION = '[IndexedDB] Operation add to Pending List';
export const ADD_PENDING_OPERATION_SUCCESS = '[IndexedDB] Operation added to Pending List successfuly';
export const DELETE_PENDING_OPERATION = '[IndexedDB] Delete Operation from Pending List';
export const DELETE_PENDING_OPERATION_SUCCESS = '[IndexedDB] Deleted Operation from Pending List successfuly';

export const DELETE_OPERATION_FROM_FETCH_LIST = '[IndexedDB] Delete Operation from fetch list';
export const DELETE_OPERATION_FROM_FETCH_LIST_SUCCESS = '[IndexedDB] Delete Operation from fetch list successfuly';

export const TRY_UPLOAD_OPERATION = '[Finish component] Try upload finished operation to server';
export const OPERATION_UPLOADED_SUCCESSFULY = '[API] Operation uploaded successfuly';
export const OPERATION_UPLOADED_FAIL = '[API] Operation uploaded failed';

/** */
export const TRY_APPLY_STEP = '[Operations step] Try save step data to db';

export const TRY_APPLY_REQUEST = '[Requests page] Try save request to db';
export const TRY_APPLY_TOOLS = '[Tools page] Try save tools to db';
export const TRY_APPLY_OPERATORS = '[Operators page] Try save operators to db';
export const TRY_APPLY_TASKS = '[Tasks page] Try save tasks to db';
export const TRY_APPLY_DRAFT_TASKS = '[Tasks page] Try apply draft tasks';
export const TRY_APPLY_WAREHOUSE = '[Tasks page] Try apply warehouse';
export const TRY_APPLY_CALIBRATIONS = '[Calibration page] Try save calibration tasks to db';

export const GO_TO_STATION = '[App] Navigate to station';
export const NAVIGATE_TO_DASHBOARD = '[Finish Page] Navigate to dashboard';
export const RESET_STATE = '[StepState] Reset';

export class StartOperation implements Action {
  readonly type = START_OPERATION;
  constructor(public payload: any) {}
}

export class FinishOperation implements Action {
  readonly type = FINISH_OPERATION;
  constructor(public payload: any) {}
}

export class FinishOperationSuccess implements Action {
  readonly type = FINISH_OPERATION_SUCCESS;
}

export class OperationNew implements Action {
  readonly type = OPERATION_NEW;
  constructor(public payload: number) {}
}

export class OperationExist implements Action {
  readonly type = OPERATION_EXIST;
  constructor(public payload: number) {}
}

export class TryUploadOperation implements Action {
  readonly type = TRY_UPLOAD_OPERATION;
  constructor(public payload: any) {}
}

export class StartNavigationTo implements Action {
  readonly type = START_NAVIGATION_TO_STEP;
  constructor(public payload: string) {}
}

export class SetIndex implements Action {
  readonly type = SET_INDEX;
  constructor(public payload: number) {}
}

export class SetName implements Action {
  readonly type = SET_NAME;
  constructor(public payload: string) {}
}

export class SetExtraStepList implements Action {
  readonly type = SET_EXTRA_STEP_LIST;
}

export class SetCommonStepList implements Action {
  readonly type = SET_COMMON_STEP_LIST;
}

export class CompeleteNotification implements Action {
  readonly type = COMPLETE_NOTIFICATION;
}

export class OperationUploadedSucces implements Action {
  readonly type = OPERATION_UPLOADED_SUCCESSFULY;
}

export class AddPendingOperation implements Action {
  readonly type = ADD_PENDING_OPERATION;
  constructor(public payload: Array<any>) {}
}

export class DeletePendingOperation implements Action {
  readonly type = DELETE_PENDING_OPERATION;
  constructor(public payload: number) {}
}

export class NavigateToDashboard implements Action {
  readonly type = NAVIGATE_TO_DASHBOARD;
}

export class ResetState implements Action {
  readonly type = RESET_STATE;
}

export class TryApplyRequest implements Action {
  readonly type = TRY_APPLY_REQUEST;
  constructor(public payload: any) {}
}

export class TryApplyTools implements Action {
  readonly type = TRY_APPLY_TOOLS;
  constructor(public payload: any) {}
}

export class TryApplyOperators implements Action {
  readonly type = TRY_APPLY_OPERATORS;
  constructor(public payload: any) {}
}

export class TryApplyTasks implements Action {
  readonly type = TRY_APPLY_TASKS;
  constructor(public payload: any) {}
}

export class TryApplyWarehouse implements Action {
  readonly type = TRY_APPLY_WAREHOUSE;
  constructor(public payload: any) {}
}

export class TryApplyDraftTasks implements Action {
  readonly type = TRY_APPLY_DRAFT_TASKS;
  constructor(public payload: any) {}
}

export class TryApplyCalibrations implements Action {
  readonly type = TRY_APPLY_CALIBRATIONS;
  constructor(public payload: any) {}
}

export class TryApplyStep implements Action {
  readonly type = TRY_APPLY_STEP;
  constructor(public payload: {data: any; tableName: string; navigateTo: string}) {}
}

export type StepActions =
  | StartOperation
  | OperationNew
  | OperationExist
  | FinishOperation
  | FinishOperationSuccess
  | TryUploadOperation
  | StartNavigationTo
  | SetIndex
  | SetName
  | AddPendingOperation
  | ResetState
  | TryApplyRequest
  | TryApplyTools
  | TryApplyOperators
  | TryApplyTasks
  | TryApplyDraftTasks
  | TryApplyCalibrations
  | TryApplyWarehouse
  | CompeleteNotification
  | OperationUploadedSucces
  | DeletePendingOperation
  | NavigateToDashboard
  | SetExtraStepList
  | SetCommonStepList
  | TryApplyStep;
