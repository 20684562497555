import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as AuthActions from './actions';

import { concatMap, mergeMap, tap, catchError, filter, switchMap, merge } from 'rxjs/operators';
import { DbService } from '../../services/db.service';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { DbActions } from '../db';
import { from } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Injectable()
export class AuthEffects {
  constructor(private action$: Actions, private db: DbService, private api: ApiService, private router: Router, private authService: AuthService) { }

  @Effect()
  checkAuthState$ = this.action$.pipe(
    ofType(AuthActions.CHECK_AUTH_STATE_BOOTSTRAP),
    concatMap(() =>
      from(this.db.getJWT()).pipe(
        filter(jwt => jwt.length > 0),
        mergeMap(jwt => [
          { type: AuthActions.FIND_USER_CREDENTIALS, payload: jwt[0].username },
          { type: AuthActions.SET_JWT_TOKEN_TEMPORARY, payload: jwt[0] },
          { type: DbActions.CONTROL_VERSION }
        ]),
        catchError(err => [{ type: AuthActions.DB_ERROR, payload: 'Error: db.getJWT()' }])
      )
    )
  );

  @Effect()
  $trySignInInstall = this.action$.pipe(
    ofType(AuthActions.TRY_SIGN_IN_INSTALL),
    concatMap(action =>
      this.api.auth(action['payload']).pipe(
        mergeMap(response => {
          switch (response['status']) {
            case 200:
              const username = action['payload']['username'];
              const password = action['payload']['password'];
              return [
                { type: AuthActions.SET_JWT_TOKEN_TEMPORARY, payload: response },
                { type: AuthActions.AUTH_SUCCESS },
                { type: DbActions.INIT_DB },
                { type: AuthActions.SET_USERNAME_AND_PASSWORD, payload: { username, password } }
              ];
            case 401:
              return [{ type: AuthActions.SIGN_IN_FAILED, payload: '[API Error] Unauthorized user' }];
            default:
              return [{ type: AuthActions.SIGN_IN_FAILED, payload: 'Error Login' }];
          }
        }),
        catchError(err => {
          console.error('Error:', err);
          return [{ type: AuthActions.SIGN_IN_FAILED, payload: '[API Connection Error] Error Login' }];
        })
      )
    )
  );

  @Effect()
  $trySignInNormal = this.action$.pipe(
    ofType(AuthActions.TRY_SIGN_IN_LAUNCH),
    mergeMap(action => [{ type: AuthActions.TRY_AUTHENTICATE_IN_APP, payload: action['payload'] }])
  );

  @Effect({ dispatch: false })
  $trySignInGuest = this.action$.pipe(
    ofType(AuthActions.TRY_SIGN_IN_GUEST),
    tap(() => this.router.navigateByUrl('/guest'))
  );

  @Effect()
  findUserCredentials$ = this.action$.pipe(
    ofType(AuthActions.FIND_USER_CREDENTIALS),
    mergeMap(action =>
      from(this.db.getUser(action['payload'])).pipe(
        mergeMap(user => [{ type: AuthActions.SET_USER_CREDENTIALS, payload: user }, { type: AuthActions.SIGN_IN }]),
        catchError(err => [
          { type: AuthActions.SIGN_IN_FAILED, payload: '[IndexedDB Error] User not found in DB. Error Login' }
        ])
      )
    )
  );

  @Effect()
  setJWT$ = this.action$.pipe(
    ofType(AuthActions.SET_JWT_TOKEN),
    mergeMap(action =>
      from(this.db.setJWT(action['payload']['token'], action['payload']['username'])).pipe(
        mergeMap(() => [{ type: AuthActions.SET_JWT_TOKEN_SUCCESS }]),
        catchError(err => {
          console.error('Error set JWT to DB:', err);
          return [{ type: AuthActions.SIGN_IN_FAILED, payload: '[IndexedDB Error] Set JWT faild. Error Login' }];
        })
      )
    )
  );

  @Effect({ dispatch: false })
  setUsernamePassword$ = this.action$.pipe(
    ofType(AuthActions.SET_USERNAME_AND_PASSWORD),
    mergeMap(action =>
      this.db.setUsernameAndPassword(action['payload']['username'], action['payload']['password']))
  );

  @Effect()
  $guestSelected = this.action$.pipe(
    ofType(AuthActions.GUEST_SELECTED),
    mergeMap(action => [
      { type: AuthActions.SET_USER_CREDENTIALS, payload: action['payload'] },
      { type: DbActions.CONTROL_VERSION_FAIL },
      { type: AuthActions.SET_GUEST_MODE },
      { type: AuthActions.SIGN_IN_GUEST_SUCCESS }
    ])
  );

  @Effect()
  $signInGuestSuccess = this.action$.pipe(
    ofType(AuthActions.SIGN_IN_GUEST_SUCCESS),
    mergeMap(() => [{ type: AuthActions.STOP_AUTH }]),
    tap(() => this.router.navigateByUrl('/app'))
  );

  @Effect()
  $signIn = this.action$.pipe(
    ofType(AuthActions.SIGN_IN),
    mergeMap(() => [{ type: AuthActions.AUTH_SUCCESS }, { type: AuthActions.STOP_AUTH }]),
    tap(() => this.router.navigateByUrl('/app'))
  );

  @Effect()
  $tryAuthenticateInApp = this.action$.pipe(
    ofType(AuthActions.TRY_AUTHENTICATE_IN_APP),
    mergeMap(action =>
      this.api.auth(action['payload']).pipe(
        mergeMap(response => {
          switch (response['status']) {
            case 200:
              const username = action['payload']['username'];
              const password = action['payload']['password'];
              return [
                { type: AuthActions.SET_JWT_TOKEN_TEMPORARY, payload: response },
                { type: AuthActions.SET_JWT_TOKEN, payload: response },
                { type: AuthActions.SET_USERNAME_AND_PASSWORD, payload: { username, password } },
                { type: AuthActions.FIND_USER_CREDENTIALS, payload: response['username'] }
              ];
            case 401:
              return [{ type: AuthActions.AUTHENTICATE_IN_APP_FAILED, payload: '[API Error] Unauthorized user' }];
            default:
              return [{ type: AuthActions.AUTHENTICATE_IN_APP_FAILED, payload: 'Error Login' }];
          }
        }),
        catchError(err => {
          console.error('Error:', err);
          return [{ type: AuthActions.AUTHENTICATE_IN_APP_FAILED, payload: '[API Connection Error] Error Login' }];
        }),
        mergeMap((prevResponse) =>
          from(this.db.getUsernameAndPassword()).pipe(
            mergeMap(response => {
              if (prevResponse['payload'] === '[API Connection Error] Error Login') {
                const canOffline = this.authService.checkCorrectUsernameAndPassword(action['payload']['username'], action['payload']['password'],
                  response[0]['username'], response[0]['password']);
                if (canOffline) {
                  return [
                    { type: AuthActions.FIND_USER_CREDENTIALS, payload: action['payload']['username'] }
                  ];
                } else {
                  return [{ type: AuthActions.AUTHENTICATE_IN_APP_FAILED, payload: '[API Error] Unauthorized user offline' }];
                }
              } else {
                return [prevResponse];
              }
            })
          )
        ),
      )
    )
  );
  @Effect()
  $logout = this.action$.pipe(
    ofType(AuthActions.LOGOUT),
    concatMap(() => this.db.deleteJWT()),
    tap(() => this.router.navigateByUrl('/auth')),
    mergeMap(() => [{ type: DbActions.RESET_STATE }])
  );
}
