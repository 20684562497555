import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Observable, Subscription, from, of } from 'rxjs';
import {
  filter,
  tap,
  distinctUntilChanged,
  map,
  concatMap,
  skip,
  withLatestFrom,
  mergeMap,
  reduce,
  catchError
} from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { RootStoreState } from '../../root-store';
import { CameraAction } from '../../root-store/camera';

import { FileManagerService } from '../../services/file-manager.service';
import { ImageManagerService } from '../../services/image-manager.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-photo-camera',
  templateUrl: './photo-camera.component.html',
  styleUrls: ['./photo-camera.component.css']
})
export class PhotoCameraComponent implements OnInit, OnDestroy {
  @Input() cameraState: Observable<any>;

  @Input() layoutFixed: Boolean;
  @Output() blobUrl = new EventEmitter();
  @Output() blobFile = new EventEmitter();
  @Output() GPSCoordinates = new EventEmitter();

  @Output() multiFiles = new EventEmitter();

  uploadImage$: Observable<any>;
  processingPhotoPlaceholder = false;

  blobUrlSubscription: Subscription;
  constructor(
    private store: Store<RootStoreState.State>,
    private fileManager: FileManagerService,
    private imageManager: ImageManagerService,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.blobUrlSubscription = this.store
      .select('camera')
      .pipe(
        skip(1),
        map(state => state.urlBlob),
        distinctUntilChanged(),
        withLatestFrom(this.store.select('camera')),
        tap(([blobUrl, state]) => {
          this.blobUrl.emit(state.urlBlob);
          this.blobFile.emit(state.fileBlob);
        })
      )
      .subscribe();
  }

  startCamera() {
    this.store.dispatch(new CameraAction.StartCamera());
  }

  stopCamera() {
    this.store.dispatch(new CameraAction.StopCamera());
  }

  takeSnapshot() {
    this.store.dispatch(new CameraAction.MakePhoto());
  }

  flipCamera() {
    this.store.dispatch(new CameraAction.FlipCamera());
  }

  uploadImage() {
    this.uploadImage$ = this.fileManager.uploadImage().pipe(
      tap(() => (this.processingPhotoPlaceholder = true)),
      mergeMap(files =>
        from(files).pipe(
          concatMap(file =>
            of(file).pipe(
              filter(_file => _file['type'].match(/image/gm)),
              concatMap((_file: File) => this.imageManager.getImageInfo(_file))
            )
          ),
          reduce((acc, _file, index) => {
            const urlBlob = window.URL.createObjectURL(_file['blob']);
            const imageItem = {
              blobURL: urlBlob,
              blobFile: _file['blob'],
              coord: _file['coord']
            };

            acc.push(imageItem);
            return acc;
          }, [])
        )
      ),
      tap(images => {
        if (images.length && images.length === 1) {
          // Emit all what we need for one image
          this.blobUrl.emit(images[0].blobURL);
          this.blobFile.emit(images[0].blobFile);
          this.GPSCoordinates.emit(images[0].coord);
        }

        this.processingPhotoPlaceholder = false;
        this.multiFiles.emit(images);
      }),
      catchError(err => {
        this.processingPhotoPlaceholder = false;
        const errMsg = `PhotoCamera.component.ts: ${err.toString()}`;
        console.error(err);
        return this.api.sendError(errMsg);
      })
    );
  }

  ngOnDestroy(): void {
    this.blobUrlSubscription.unsubscribe();
    this.store.dispatch(new CameraAction.StopCamera());
  }
}
