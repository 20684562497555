import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { AuthModule } from './auth/auth.module';
import { StationModule } from './station/station.module';
import { CameraModule } from './camera/camera.module';
import { StepModule } from './step/step.module';
import { SystemModule } from './system/system.module';
import { DbModule } from './db/db.module';

@NgModule({
  imports: [
    CommonModule,
    AuthModule,
    StationModule,
    CameraModule,
    StepModule,
    SystemModule,
    DbModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([])
  ],
  declarations: []
})
export class RootStoreModule { }
