export interface CameraState {
    live: boolean; // WebCamera are live
    recording: boolean; // Stream is recording
    permissionAllowed: boolean; // User allow use web cam or not
    streamDimension: Object; // Dimension of stream (700px * 300px) for example
    stream: any; // Store stream object
    flip: boolean; // Camera flipped (180degree) or not
    connecting: boolean; // Camera connect or not to pc
    error: string; // Error
    urlBlob: any; // URL blob for stored snapshot
    fileBlob: any; // Blob file of stored snapshot
}

export const initialState: CameraState = {
    live: false,
    recording: false,
    permissionAllowed: false,
    streamDimension: null,
    stream: null,
    flip: false,
    connecting: false,
    error: null,
    urlBlob: null,
    fileBlob: null
};
