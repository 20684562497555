import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { mergeMap, first, tap, catchError, delay, concatMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { RootStoreState, AuthAction } from '../root-store';

@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(private store: Store<RootStoreState.State>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select('auth').pipe(
      first(),
      mergeMap(authState => {         
        //Auth intercept
        if (request.url.endsWith('/api/auth') && request.method === 'POST') {     
          this.store.dispatch(new AuthAction.StartAuth());     
          return next.handle(request).pipe(
            catchError(err => { this.store.dispatch(new AuthAction.StopAuth()); return throwError(err)})
          );
        } 

        //Other intercept        
        request = request.clone({
          setHeaders: {
            'x-access-token': `${authState.jwt['token']}`
          }
        });

        return next.handle(request);
      })
    )
  }
}

export const jwtProvider = {
  // use backendProvider in place of Http service for JWT auth
  provide: HTTP_INTERCEPTORS,
  useClass: InterceptorService,
  multi: true
};
