import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { dbReducer } from './reducers';
import { DbEffects } from './effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('db', dbReducer),
    EffectsModule.forFeature([DbEffects])
  ],
  declarations: []
})
export class DbModule { }
