import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { DbService } from 'src/app/services/db.service';

import { Observable, from } from 'rxjs';
import { map, concatMap, catchError, distinctUntilChanged, tap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

/**
 * Display all requests which available in system. For all networks and all stations.
 *
 * @export
 * @class RequestsListComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-requests-list',
  templateUrl: './requests-list.component.html',
  styleUrls: ['./requests-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestsListComponent implements OnInit {
  requests$ = this.store$.select('station').pipe(
    map(({ requestsList }) => requestsList),
    distinctUntilChanged()
  );

  requestsList$: Observable<any>;
  selectedRequests: any[];

  constructor(
    private db: DbService,
    private store$: Store<any>,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.requestsList$ = this.requests$.pipe(
      concatMap(requests =>
        from(this.db.getTableData('smart-pga', 'app_operation')).pipe(
          map(operations => {
            return requests.map(_req => {
              const cardState = operations.find(operation => {
                if (operation.request_id)
                  return operation.request_id.filter(x => x === _req.id)[0]
                else
                  return null;
              })
                ? 'in-progress'
                : 'none';
              _req['cardState'] = cardState;
              return _req;
            });
          })
        )
      ),
      catchError(err => err)
    );
    this.selectedRequests = [];
  }

  selectRequest(request): void {
    if (this.selectedRequests.indexOf(request) === -1) {
      if (this.selectedRequests.length === 0 || this.selectedRequests[0].id_stazione === request.id_stazione) {
        this.selectedRequests.push(request);
      }
    } else {
      this.selectedRequests.splice(this.selectedRequests.indexOf(request), 1);
    }
  }

  isSelected(request): boolean {
    if (this.selectedRequests.indexOf(request) === -1) {
      return false;
    }
    return true;
  }

  getSelectedRequstIds(): number[] {
    const idArray: number[] = [];
    for (let i = 0; i < this.selectedRequests.length; i++) {
      idArray.push(this.selectedRequests[i].id);
    }
    return idArray;
  }

  startOperation() {
    if (this.selectedRequests.length > 0) {
      const request = this.selectedRequests[0];
      this.router.navigate(['../operation/new'], {
        relativeTo: this.route,
        queryParams: {
          stationId: request.id_stazione,
          networkId: request.id_rete,
          // requestId: request.id
          requestId: this.getSelectedRequstIds()
        }
      });
    }
  }
}
