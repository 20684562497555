import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';

import { RootStoreState, SystemActions, SystemSelectors } from '../root-store';
import { AuthAction } from '../root-store';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material';
import { AuthDialogComponent } from '../dialogs/auth-dialog/auth-dialog.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  public isOnline: boolean

  @Input() authState: Observable<any>;
  @Input() isOpen: boolean;

  @HostListener('click') close() {
    this.store.dispatch(new SystemActions.CloseSidebar());
  }

  constructor(private store: Store<RootStoreState.State>, private dialog: MatDialog) {}

  ngOnInit() {
    this.store.select(SystemSelectors.onlineSelector).subscribe(
      value => this.isOnline = value
    );
  }

  logout() {
    this.store.dispatch(new AuthAction.Logout());
  }

  auth(): void {
    const dialogRef = this.dialog.open(AuthDialogComponent, {
      width: '300px'
    });
  }
}
