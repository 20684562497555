import { initialState } from './state';

import * as Actions from './actions';

export function authReducer(state = initialState, action: Actions.AuthActions) {
    switch (action.type) {
        case Actions.AUTH_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                guestMode: false,
                errorMessage: null
            };   

        case Actions.SET_USER_CREDENTIALS:        
            return {
                ...state,
                credentials: { ...action.payload }
            };

        case Actions.LOGOUT: 
            return {
                ...state,
                isAuthenticated: false,
                credentials: {
                    username: null,
                    descr: null,
                    role: '@'
                },
                jwt: {}
            };

        case Actions.SIGN_IN_FAILED:   
        case Actions.AUTHENTICATE_IN_APP_FAILED:         
            return {
                ...state,
                isAuthenticated: false,
                errorMessage: action.payload
            };   
        
        case Actions.SET_JWT_TOKEN_TEMPORARY:            
            return {
                ...state,
                jwt: {... action.payload}
            };
        case Actions.SET_GUEST_MODE:
            return {
                ...state,
                guestMode: true
            };
        case Actions.RESET_GUEST_MODE:
            return {
                ...state,
                guestMode: false
            };
        case Actions.START_AUTH:
            return {
                ...state,
                authenticating: true
            };
        
        case Actions.STOP_AUTH:
            return {
                ...state,
                authenticating: false
            };
        
        case Actions.TRY_SIGN_IN_INSTALL:
        case Actions.TRY_SIGN_IN_LAUNCH:
            return {
                ...state,
                errorMessage: null
            };
        default:            
            return state;
    }
}