import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StationState } from './state';

export const stationSelector = createFeatureSelector<StationState>('station');

export const networksSelector = createSelector(
    stationSelector,
    (state: StationState) => state.networksList
);

export const stationsSelectors = createSelector(
    stationSelector, 
    (state: StationState) => state.stationsList
);