import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { StationAction, StationSelectors, StationState } from 'src/app/root-store/station';
import { SystemSelectors } from 'src/app/root-store/system';
import { ApiService } from 'src/app/services/api.service';
import { DbService } from 'src/app/services/db.service';


@Component({
  selector: 'app-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.css']
})
export class DocumentsListComponent implements OnInit {
  networks: Array<any> = [];
  stations: Array<any> = [];
  filteredStations: Array<any> = [];
  filteredStationDocuments: Array<any> = [];
  stationSelected: any = null;
  isOnline: boolean;
  documentsForm: FormGroup
  documentsTypes: Array<any> = [];
  documentsTypesMap: Map<number, string> = new Map<number, string>();
  documents: Array<any> = [];
  displayedColumns: Array<String> = ['name', 'type', 'download'];
  isLoading = false;

  constructor(
    private store$: Store<any>,
    private dbService: DbService,
    private apiService: ApiService,
    private formBuilder: FormBuilder) {

    this.documentsForm = this.formBuilder.group({
      network: [null],
      station: [null],
      documentType: [null],
    });
  }

  ngOnInit() {
    this.store$.select(StationSelectors.networksSelector).subscribe(
      value => this.networks = value
    );

    this.store$.select(StationSelectors.stationsSelectors).subscribe(
      value => this.stations = value
    );

    this.store$.select(SystemSelectors.onlineSelector).subscribe(
      value => this.isOnline = value
    );

    this.dbService.getTableData('smart-pga_documentstypes', 'documentstypes', 'readonly')
    .then(response => {
      this.documentsTypes = response;
      this.documentsTypes.map(documentType => this.documentsTypesMap.set(documentType.id, documentType.descr));
    });
  }

  onNetworkSelected(event: any): void {
    this.filteredStations = [];
    this.documents = [];
    this.filteredStationDocuments = [];

    if (event.value === null) {
      return;
    }

    const idNetwork: string = event.value as string;
    this.filteredStations = this.stations.filter(station => station.id_rete === idNetwork);
  }

  onStationSelected(event: any): void {
    this.documents = [];
    this.filteredStationDocuments = [];

    if (event.value === null) {
      return;
    }

    const station = event.value;

    this.isLoading = true;

    this.apiService.getStation(station.id_stazione, station.id_cliente)
    .subscribe(
      (response) => {
        this.stationSelected = response;
        const documentType = this.documentsForm.get('documentType').value;
        this.filteredStationDocuments = [... this.filterStationsDocuments(this.stationSelected, documentType)];

        this.isLoading = false;
      },
      (error) => { 
        console.error(error); 
        this.isLoading = false;
      }
    )
  }

  onDocumentTypeSelected(event: any): void {
    const documentType = event.value;

    this.filteredStationDocuments = [... this.filterStationsDocuments(this.stationSelected, documentType)];
  }

  onDocumentDownload(stationDocument: any): void {
    this.isLoading = true;

    this.apiService.downloadDocument(stationDocument.name, stationDocument.ext)
    .subscribe(
      data => {
        this.isLoading = false;

        const file = new Blob([data], { type: 'application/pdf' });
        const fileUrl = URL.createObjectURL(file);
        window.open(fileUrl);
      }, 
      error => {
        this.isLoading = false;
        console.error(error);
    });
  }
  
  onResetForm(): void {
    this.documents = [];
    this.filteredStationDocuments = [];
    this.documentsForm.reset();
  }

  private filterStationsDocuments(station: any, documentType: number | null = null): Array<any> {
    let filteredStationDocuments: Array<any> = [];

    if (station === null || station.length === 0) {
      return;
    }

    this.documents = station.documents;
    filteredStationDocuments = [... this.documents];
    
    if (this.documentsTypes !== null) {
      filteredStationDocuments = this.documents.filter(
        (stationDocument) => this.documentTypeMatch(stationDocument, documentType));
    }

    return filteredStationDocuments;
  } 

  private documentTypeMatch(stationDocument, documentType): boolean {
    if (documentType === null) {
      return true;
    }
    return stationDocument.type === documentType;
  }
}
