import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { MatTableModule, MatInputModule,
  MatButtonModule, MatIconModule, MatRippleModule ,
  MatTooltipModule, MatSelectModule, MatButtonToggleModule,
  MatRadioModule, MatDatepickerModule, MatNativeDateModule,
  MatProgressBarModule, MatCheckboxModule, MatDialogModule, MatChipsModule, 
  MatProgressSpinnerModule } from '@angular/material';

  import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';


@NgModule({
  imports: [
    CommonModule,  FlexLayoutModule,
    OwlDateTimeModule, OwlNativeDateTimeModule,
    MatTableModule, MatInputModule, MatButtonToggleModule,
    MatButtonModule, MatIconModule, MatTooltipModule,  MatRippleModule ,
    MatSelectModule, MatRadioModule, MatDatepickerModule,
    MatNativeDateModule, MatProgressBarModule, MatCheckboxModule, MatDialogModule, MatChipsModule,
    MatProgressSpinnerModule
  ],
  exports: [
    FlexLayoutModule,
    OwlDateTimeModule, OwlNativeDateTimeModule,
    MatTableModule, MatInputModule, MatButtonToggleModule,
    MatButtonModule, MatIconModule, MatTooltipModule, MatRippleModule,
    MatSelectModule, MatRadioModule, MatDatepickerModule,
    MatNativeDateModule, MatProgressBarModule , MatCheckboxModule, MatDialogModule, MatChipsModule,
    MatProgressSpinnerModule
  ],
  declarations: []
})
export class MaterialsModule { }
