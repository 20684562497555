import { Injectable } from '@angular/core';

import { fromEvent, merge } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SystemManagerService {

  constructor() { }

  connectionStatusMonitoring(onlineHandler, offlineHandler): void {
    const offline$ = fromEvent(window, 'offline');
    const online$ = fromEvent(window, 'online');

    merge(offline$, online$).pipe(
      map(event => event.type),
      tap(type => type === 'online' ? onlineHandler() : offlineHandler())      
    ).subscribe();

    //Check online status
    navigator.onLine ? onlineHandler() : offlineHandler();
  }
}
