import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { RootStoreState, AuthAction, SystemActions } from '../root-store';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { DbService } from '../services/db.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {
  form: FormGroup;

  authState: Observable<any>;
  dbState: Observable<any>;
  systemState: Observable<any>;
  constructor(private store: Store<RootStoreState.State>, private formBuilder: FormBuilder, private db:DbService) { }

  ngOnInit() {
    this.authState = this.store.select('auth'); 
    this.systemState = this.store.select('system');   
    this.dbState = this.store.select('db');  

    //Set Form
    this.form = this.formBuilder.group({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });   
  }

  getLoginFormDate():{ username: string, password: string } {
    const username: string = this.form.get("username").value;
    const password: string = this.form.get("password").value;

    username.trim();
    password.trim();

    const payload = {
      username: username,
      password: password
    };

    return payload;
  }

  login():void {
    const loginData = this.getLoginFormDate();
    this.store.dispatch(new AuthAction.TrySignInLaunch(loginData))
  }

  guest():void {
    this.store.dispatch(new AuthAction.TrySignInGuest())
  }

  install():void {    
    const loginData = this.getLoginFormDate();  
    this.store.dispatch(new AuthAction.TrySignInInstall(loginData));
  }
}
