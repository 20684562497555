import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class HashCodeService {

    constructor() {
    }

    convertIntoHashCode(value: string): string {
        let hash = 0;
        if (value.length == 0) {
            return String(hash);
        }
        for (let i = 0; i < value.length; i++) {
            const char = value.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash;
        }
        return String(hash);
    }

}