import { Component, ChangeDetectionStrategy, Output, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-images-uploaded-buffer',
  template: '<app-add-photo-button [isDisabled]="isDisabled"  (files$)="getImageFiles($event)"></app-add-photo-button>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagesUploadedBufferComponent {
  /**
   * Emit value of array blob files
   *
   * @memberof ImagesUploadedBufferComponent
   */
  @Output()
  imageBlob$ = new BehaviorSubject([]);

  @Input()
  isDisabled: boolean = false;


  // Input service method which delete something
  constructor() { }

  getImageFiles(files): void {
    files.forEach(async file => {
      if (!file.blobFile) {
        return;
      }

      this.imageBlob$.next(file.blobFile);
    });
  }
}
