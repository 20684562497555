import { initialState } from './state';
import * as Actions from './actions';

export function stepReducer (state = initialState, action: Actions.StepActions) {
    switch (action.type) {
        case Actions.SET_INDEX:            
            return {
                ... state,
                index: action.payload
            }
        case Actions.SET_NAME:           
            return {
                ... state,
                name: action.payload
            }
        case Actions.RESET_STATE:           
            return {
                ... initialState
            }
        case Actions.SET_EXTRA_STEP_LIST:     
        const steps = [...initialState.list];
        steps.splice(steps.indexOf('tasks'), 1);
            return {
                ... state,
                list: [...steps]
            };
        case Actions.SET_COMMON_STEP_LIST:     
            return {
                ... state,
                list: [...initialState.list]
            };      
        default:
            return { ... state };
    }
}