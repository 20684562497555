export interface StepState {
  list: any; // Array of available steps
  index: number; // Current step index
  name: string; // Current step name
  done: boolean; // If steps are finished
}

export const initialState: StepState = {
  list: ['station', 'notification', 'request', 'tools', 'tasks', 'calibration', 'warehouse', 'operators', 'finish'],
  index: 0,
  name: 'station',
  done: false
};
