export interface DbState {
    version: number; // Version of DB
    installed: boolean; // If DB installed already in app or not
    failedUpgrade: boolean; // If fetched new DB was failed
    ready: boolean; // DB ready to work
    error: string; // Error
    status: string; // Status of DB 

};

export const initialState: DbState = {
    version: null,
    installed: false,
    failedUpgrade: false,
    ready: false,
    error: null,
    status: null,
}