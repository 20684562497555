export const prototypeDB = {
    //_user: ['password', 'role', 'firstName', 'lastName', 'operator_id', 'role'],   
    //_station: ['id_network', 'name', 'lati1', 'lati2', 'lati3', 'long1', 'long2', 'long3', 'notification_note', 'manord'],
    //_network: ['name'],   
    app_operation:['id_user', 'username', 'type', 'timeStamp', 'photo_before'],
    app_pending_operation:[],
    app_pending_files:[],
    app_pending_errors: []
};


export const prototypeDBv2 = {   
    /*toolsList: ['expiredDate', 'type'],
    operation: ['date','time','visualInspection','voltageBattery', 'hydrometricLevel', 'callStation', 'warning', 'alarm', 'foto', 'operators_ids', 'tools_ids', 'station_id'],
    operationHours: ['operator_id','journeyDuration', 'journeyDistance', 'foodtickets','overnight','interventionTime','workerTime'],*/
    _tool: ['id', 'type', 'expiredDate'],
    _task: ['id_station', 'checkbox', 'textFields'],    
    app_operation:['id_station','id_user', 'username', 'type', 'timeStamp', 'photo_before'],
    app_fetch: ['id_','time','visualInspection','voltageBattery', 'hydrometricLevel', 'callStation', 'warning', 'alarm', 'foto', 'operators_ids', 'tools_ids', 'station_id'],
};

//TODO: should be set version of task. Maybe using the data of task creation will be usefull