import { initialState } from './state';

import * as Actions from './actions';

export function stationReducer(state = initialState, action: Actions.StationActions) {
  switch (action.type) {
    case Actions.ACTIVATE_GPS:
      return {
        ...state,
        GPSActive: true
      };
    case Actions.SET_GPS_COORD:
      return {
        ...state,
        GPSCoord: {
          ...action.payload
        }
      };
    case Actions.SET_PHOTO_BEFORE:
      // const url = window.URL.createObjectURL(action.payload);
      return {
        ...state,
        data: {
          ...state.data,
          start_photo: action.payload
        }
      };
    case Actions.DELETE_PHOTO_BEFORE:
      return {
        ...state,
        data: {
          ...state.data,
          start_photo: null
        }
      };
    case Actions.SET_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      };
    case Actions.RESET_DATA:
      return {
        ...state,
        notificationFailed: false,
        GPSCoord: null,
        data: { ...initialState.data }
      };
    case Actions.SET_STATIONS:
      return {
        ...state,
        stationsList: action.payload
      };
    case Actions.SET_NETWORKS:
      return {
        ...state,
        networksList: action.payload
      };
    case Actions.ONLINE_NOTIFICATION_FAIL:
      return {
        ...state,
        notificationFailed: true
      };
    case Actions.ONLINE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationFailed: false
      };

    case Actions.POPULATE_OPERATION_DATA:
      return {
        ...state,
        data: { ...action.payload }
      };

    case Actions.SET_REQUESTS:
      return {
        ...state,
        requestsList: action.payload
      };

    case Actions.SET_REQUEST_ID:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      };

    case Actions.DELETE_UPLOADED_OPERATION_REQUEST:
      const requestId = action.payload;

      const requestIndex = state.requestsList.findIndex(req => req.id === requestId);
      const requestsArr = [...state.requestsList];

      requestsArr.splice(requestIndex, 1);

      return {
        ...state,
        requestsList: [...requestsArr]
      };
    default:
      return state;
  }
}
