import { Injectable } from '@angular/core';
import { observable, Observable, zip } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { nextTick } from 'process';
import { DbService } from './db.service';

export const API = environment.api;
export const API_AWS = environment.apiAWS;
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private dbService: DbService
  ) {}

  fetchDB(): Observable<any> {
    return zip(
      this.http.get(`${API}/api/users`),
      this.http.get(`${API}/api/stations`),
      this.http.get(`${API}/api/networks`),
      this.http.get(`${API}/api/tools`),
      this.http.get(`${API}/api/tasks`),
      this.http.get(`${API}/api/calibration`),
      this.http.get(`${API}/api/dataselect`),
      this.http.get(`${API}/api/registryDocuments`)
    );
  }

  sendError(errMsg: string): Observable<any> {
    const body = {
      msg: errMsg
    };
    return this.http.post(`${API_AWS}/notify`, body);
  }

  notify(): Promise<any> {
    const STATUS = Math.floor(Math.random() * (201 - 200 + 1) + 200);
    return Promise.resolve(STATUS);
  }

  auth(user: any): Observable<any> {
    return this.http.post(`${API}/api/auth`, user);
  }

  uploadOperation(operationData: any): Observable<any> {
    return this.http.post(`${API}/api/operation`, operationData);
  }

  uploadFile(fileSettings: any): Observable<any> {
    const FD = new FormData();
    let url = null;

    FD.append('file', fileSettings.file, `${fileSettings.id_by_server}-${fileSettings.type}-${fileSettings.id}.jpg`);

    switch (fileSettings.type) {
      case 'calibration':
      case 'tasks':
        url = `${API}/api/file?operation=${fileSettings.id_by_server}&type=${fileSettings.type}&id_task=${fileSettings.id_task}&index=${fileSettings.index}`;
        break;
      case 'finish':
        url = `${API}/api/file?operation=${fileSettings.id_by_server}&type=${fileSettings.type}&index=${fileSettings.index}`;
        break;
      default:
        url = `${API}/api/file?operation=${fileSettings.id_by_server}&type=${fileSettings.type}`;
    }

    return this.http.post(url, FD);
  }

  /**
   * @description Return DB Server version   *
   */
  getDBVersion(): Observable<any> {
    return this.http.get(`${API}/api/version`);
  }

  getBlobFile(url: string): Observable<any> {
    return this.http.get(url, { responseType: 'blob' });
  }

  getComponents(idStazione: number, idClient: number): Observable<any> {
    return this.http.get(`${API}/api/config_station?id_client=${idClient}&id_station=${idStazione}`);
  }

  getStores(network : string): Observable<any> {
    return this.http.get(`${API}/api/stores?rete=${network}`);
  }

  getComponentBySerialNumber(serialNumber: string): Observable<any> {
    return this.http.get(`${API}/api/find_sn?sn=` + serialNumber);
  }

  downloadDocument(documentName: string, extension: string): Observable<any> {
    const url = `${API}/api/download_doc?nameFile=${documentName}&ext=${extension}`;
    return this.http.get(url, { responseType: 'blob' });
  }

  getStation(stationId: number, customerId: string): Observable<any> {
    const url = `${API}/api/station?station=${stationId}&client=${customerId}`;
    
    return new Observable((observer) => {
      this.http.get(url).subscribe(
        (station) => {
          this.dbService.updateStation(station);
          observer.next(station);
          observer.complete();
        }, 
        (error) => observer.error(error))
    });
  }
}
