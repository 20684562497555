import { Action } from '@ngrx/store';

export const ONLINE = '[System] Connection went to Online';
export const OFFLINE = '[System] Connection went to Offline';

export const TRY_UPLOAD_PENDING_OPERATIONS = '[Pending operation] Try upload operations from pending list';
export const TRY_UPLOAD_OPERATION = '[Pending operation] Try upload operation that already uploaded';
export const LOAD_PENDING_OPERATIONS = '[Pending operation: IndexedDB] Get pending operations';

export const UPLOAD_PENDING_OPERATION = '[Pending operation] Upload pending operation';
export const UPLOAD_PENDING_OPERATION_SUCCESS = '[Pending operation] Uploaded pending operation succss';
export const UPLOAD_PENDING_OPERATION_FAIL = '[Pending operation] Uploaded pending operation failed';

export const DELETE_UPLOADED_OPERATION = '[Pending operation: IndexedDB] Delete uploaded operation from pending list';

export const TRY_DELETE_UPLOADED_OPERATION_REQUEST =
  '[Pending operation: IndexedDB] Try delete uploaded operation request from station';
export const DELETE_UPLOADED_OPERATION_SUCCESS =
  '[Pending operation: IndexedDB] Delete uploaded operation from pendig list succes';

export const TRY_UPLOAD_PENDING_FILES = '[Pending files] Try upload files from pending files list';
export const FETCH_PENDING_FILES = '[IndexedDB] Fetch pending files from DB';

export const TRY_UPLOAD_FILES = '[System] Try upload image files';

export const SAVE_FILES = '[Step -> System] Save image files to IndexedDB';
export const SAVE_FILES_FAIL = '[Step -> System] Save image files to IndexedDB failed';
export const TRY_UPLOAD_SAVED_FILES = '[System] Try upload to server saved files for finished operation';
export const UPLOAD_SAVED_FILES_FAIL = '[System] Upload to server saved files for finished operation failed';

export const PUSH_FILE = '[System -> API] Push File to server';
export const PUSH_FILE_SUCCESS = '[System -> API] Push File to server successed';
export const PUSH_FILE_FAIL = '[System -> API] Push File to server failed';

export const UPLOAD_FILE_SUCCESS = '[API] Upload image file success';
export const UPLOAD_FILE_FAIL = '[API] Upload image file failed';

export const PENDING_FILE_DELETED = '[System] Uploaded pending files try delete from DB';
export const PENDING_FILE_DELETED_SUCCESS = '[System] Uploaded pending files deleted from DB with success';
export const PENDING_FILE_DELETED_FAIL = '[System] Uploaded pending files delition from DB failed';

export const OPEN_SIDEBAR = '[System UI] Open sidebar';
export const CLOSE_SIDEBAR = '[System UI] Close sidebar';
export const TOGGLE_SIDEBAR = '[System UI] Toggle sidebar';

export const TRY_UPLOAD_PENDING_NOTIFICATIONS_ERROR = '[System] Try send pending error notification'
export const SEND_PENDING_NOTIFICATION_ERROR_SUCCESS = '[System] Send pending error notification SUCCESS'
export const DELETE_PENDING_NOTIFICATION_ERROR = '[System] Delete pending error notification'

export class Online implements Action {
  readonly type = ONLINE;
}

export class Offline implements Action {
  readonly type = OFFLINE;
}

export class TryUploadOperation implements Action {
  readonly type = TRY_UPLOAD_OPERATION;
  constructor(public payload: any) {}
}

export class TryUploadPendingOperations implements Action {
  readonly type = TRY_UPLOAD_PENDING_OPERATIONS;
}

export class LoadPendingOperations implements Action {
  readonly type = LOAD_PENDING_OPERATIONS;
}

export class UploadPendingOperation implements Action {
  readonly type = UPLOAD_PENDING_OPERATION;
  constructor(public payload: any) {}
}

export class DeleteUploadedOperation implements Action {
  readonly type = DELETE_UPLOADED_OPERATION;
  constructor(public payload: any) {}
}

export class TryDeleteUploadedOperationRequest implements Action {
  readonly type = TRY_DELETE_UPLOADED_OPERATION_REQUEST;
  constructor(public payload: any) {}
}

export class TryUploadPendingFiles implements Action {
  readonly type = TRY_UPLOAD_PENDING_FILES;
}

export class TryUploadFiles implements Action {
  readonly type = TRY_UPLOAD_FILES;
  constructor(public payload: any) {}
}

export class SaveFiles implements Action {
  readonly type = SAVE_FILES;
  constructor(public payload: any) {}
}

export class PushFile implements Action {
  readonly type = PUSH_FILE;
  constructor(public payload: any) {}
}

export class TryUploadSavedFiles implements Action {
  readonly type = TRY_UPLOAD_SAVED_FILES;
  constructor(public payload?: any) {}
}

export class PendingFileDeleted implements Action {
  readonly type = PENDING_FILE_DELETED;
  constructor(public payload: number) {}
}

export class OpenSidebar implements Action {
  readonly type = OPEN_SIDEBAR;
}

export class CloseSidebar implements Action {
  readonly type = CLOSE_SIDEBAR;
}

export class ToggleSidebar implements Action {
  readonly type = TOGGLE_SIDEBAR;
}

export class UploadPendingOperationFailed implements Action {
  readonly type = UPLOAD_PENDING_OPERATION_FAIL;
  constructor(public payload?: any) {}
}

export class TryUploadPendingErrors implements Action {
  readonly type = TRY_UPLOAD_PENDING_NOTIFICATIONS_ERROR;
}

export class DeletePendingErrors implements Action {
  readonly type = DELETE_PENDING_NOTIFICATION_ERROR;
  constructor(public payload: number) {}
}

export type SystemActions =
  | Online
  | Offline
  | TryUploadOperation
  | TryUploadPendingOperations
  | LoadPendingOperations
  | UploadPendingOperation
  | DeleteUploadedOperation
  | TryUploadFiles
  | SaveFiles
  | TryUploadSavedFiles
  | PushFile
  | OpenSidebar
  | CloseSidebar
  | ToggleSidebar
  | UploadPendingOperationFailed
  | TryUploadPendingErrors
  | DeletePendingErrors;
