import { Effect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';

import * as SystemActions from './actions';

import { from, of } from 'rxjs';
import { concatMap, map, filter, mergeMap, tap, switchMap, catchError } from 'rxjs/operators';

import { DbService } from '../../services/db.service';
import { ApiService } from '../../services/api.service';
import { Store } from '@ngrx/store';
import { RootStoreState } from '..';
import { ImageManagerService } from 'src/app/services/image-manager.service';
import { StationAction } from '../station';

@Injectable()
export class SystemEffects {
  constructor(
    private action$: Actions,
    private db: DbService,
    private api: ApiService,
    private imageService: ImageManagerService,
    private store$: Store<RootStoreState.State>
  ) {}

  @Effect()
  tryUploadOperations$ = this.action$.pipe(
    ofType(SystemActions.TRY_UPLOAD_PENDING_OPERATIONS),
    concatMap(() => this.db.getTableData('smart-pga', 'app_pending_operation')),
    filter(pendingOperationsList => pendingOperationsList.length > 0),
    switchMap(pendingOperationsList =>
      from(pendingOperationsList).pipe(
        map(pendingOperation => [
          pendingOperation['id_cliente'],
          pendingOperation['id_stazione'],
          pendingOperation['date_index'],
          pendingOperation['operation_type']
        ]),
        concatMap(operationID => this.db.getOperation(operationID)),
        concatMap(operation =>
          this.api.uploadOperation(operation).pipe(
            mergeMap(response => {
              return [
                { type: SystemActions.UPLOAD_PENDING_OPERATION_SUCCESS },
                { type: SystemActions.DELETE_UPLOADED_OPERATION, payload: operation.id },
                { type: SystemActions.TRY_DELETE_UPLOADED_OPERATION_REQUEST, payload: operation },
                { type: SystemActions.SAVE_FILES, payload: [operation, response['id']] }
              ];
            }),
            catchError(err => {
              console.error(err, operation);
              return [
                {
                  type: SystemActions.UPLOAD_PENDING_OPERATION_FAIL,
                  payload: {
                    status: 'catchError',
                    errMsg: err['message'],//.toString(),
                    operator: operation.username,
                    id_rete: operation.id_rete,
                    id_station: operation.id_stazione,
                    station: operation.nome,
                    errorTime: new Date()
                  }
                }
              ];
            })
          )
        )
      )
    )
  );

  @Effect()
  tryUploadPendingErrors$ = this.action$.pipe(
    ofType(SystemActions.TRY_UPLOAD_PENDING_NOTIFICATIONS_ERROR),
    concatMap(() => this.db.getTableData('smart-pga', 'app_pending_errors')),
    filter(pendingOperationsList => pendingOperationsList.length > 0),
    switchMap(pendingOperationsList =>
      from(pendingOperationsList).pipe(
        map((pendingOperation:any) => 
          pendingOperation),
        concatMap(operation =>
          this.api.sendError(operation.value).pipe(
            mergeMap(response => {
              return [
                { type: SystemActions.SEND_PENDING_NOTIFICATION_ERROR_SUCCESS },
                { type: SystemActions.DELETE_PENDING_NOTIFICATION_ERROR, payload: operation.id }
              ];
            }),
            catchError(err => {
              console.error(err, operation);
              return []
            })
          )
        )
      )
    ),
    catchError((err) => {
      return []
    })
  );

  @Effect()
  tryUploadOperation$ = this.action$.pipe(
    ofType(SystemActions.TRY_UPLOAD_OPERATION),
    concatMap(action =>
      this.api.uploadOperation(action['payload']).pipe(
        mergeMap(({ id }) => {
          const operation = action['payload'] as any;
          operation['uploading$'].next(false);

          return [{ type: SystemActions.SAVE_FILES, payload: [operation, id] }];
        }),
        catchError(err => {
          const operation = action['payload'] as any;
          operation['uploading$'].next(false);

          console.error(err, operation);
          return [
            {
              type: SystemActions.UPLOAD_PENDING_OPERATION_FAIL,
              payload: {
                status: 'catchError',
                errMsg: err['message'],
                operator: operation['username'],
                id_rete: operation['id_rete'],
                id_station: operation['id_stazione'],
                station: operation['nome'],
                errorTime: new Date()
              }
            }
          ];
        })
      )
    )
  );

  @Effect()
  deleteUploadedOperation$ = this.action$.pipe(
    ofType(SystemActions.DELETE_UPLOADED_OPERATION),
    concatMap(action => this.db.deletePendingOperation(action['payload'])),
    tap(() => this.db.updateDbInViews()),
    mergeMap(() => [{ type: SystemActions.DELETE_UPLOADED_OPERATION_SUCCESS }])
  );

  @Effect()
  deletePendingError$ = this.action$.pipe(
    ofType(SystemActions.DELETE_PENDING_NOTIFICATION_ERROR),
    concatMap(action => this.db.deletePendingErrors(action['payload'])),
    tap(() => this.db.updateDbInViews()),
    mergeMap(() => [{ type: SystemActions.DELETE_UPLOADED_OPERATION_SUCCESS }])
  );

  @Effect()
  tryDeleteUploadedOperationRequest$ = this.action$.pipe(
    ofType(SystemActions.TRY_DELETE_UPLOADED_OPERATION_REQUEST),
    filter(({ payload }) => payload['request']),
    mergeMap(({ payload }) => {
      const requestId = payload['request']['id'];
      const keysStation = [payload['id_cliente'], payload['id_stazione']];

      // Deleting request from dB
      this.db.deleteRequest(keysStation, requestId).then(console.log);

      return [{ type: StationAction.DELETE_UPLOADED_OPERATION_REQUEST, payload: requestId }];
    })
  );

  @Effect()
  saveFiles$ = this.action$.pipe(
    ofType(SystemActions.SAVE_FILES),
    map((action: SystemActions.SaveFiles) => action.payload),
    concatMap(([steps, idResponse]) => {
      const filesPromise = [];

      // Generate unifyed format for files
      for (const stepKey in steps.photos) {
        if (steps.photos[stepKey] && steps.photos[stepKey].length > 0) {
          steps.photos[stepKey].forEach(item => {
            const FILE = {
              ...item,
              type: stepKey
            };
            // Put Files to Indexed DB:
            filesPromise.push(this.db.addPendingFile(FILE, idResponse));
          });
        }
      }

      return from(Promise.all(filesPromise)).pipe(
        mergeMap(() => [{ type: SystemActions.TRY_UPLOAD_SAVED_FILES, payload: idResponse }]),
        catchError(err => {
          console.error('SAVE_FILES:', err);
          return [{ type: SystemActions.SAVE_FILES_FAIL }];
        })
      );
    })
  );

  @Effect()
  tryUploadSavedFiles$ = this.action$.pipe(
    ofType(SystemActions.TRY_UPLOAD_SAVED_FILES),
    map((action: SystemActions.TryUploadSavedFiles) => action.payload),
    concatMap(idByServer => from(this.db.getTableData('smart-pga', 'app_pending_files', 'readwrite'))
      .pipe(
        map(files => files.filter(file => (idByServer ? file.id_by_server === idByServer : file))))),
    mergeMap(files =>
      from(files).pipe(
        switchMap(file =>
          of(file).pipe(
            map((_file: Object) => {
              const blob = this.imageService.arrayBufferToBlob(_file['file'], _file['type']);
              const convertedFileContainer = { ..._file, file: blob };
              return convertedFileContainer;
            }),
            mergeMap(fileObj => [{ type: SystemActions.PUSH_FILE, payload: fileObj }])
          )
        )
      )
    ),
    catchError(err => {
      console.error('TRY_UPLOAD_SAVED_FILES:', err);
      return [{ type: SystemActions.UPLOAD_SAVED_FILES_FAIL }];
    })
  );

  @Effect()
  pushFile$ = this.action$.pipe(
    ofType(SystemActions.PUSH_FILE),
    mergeMap(action => {
      const FILE = { ...(action['payload'] as {}) };
      return this.api.uploadFile(FILE).pipe(
        concatMap(() => [
          { type: SystemActions.PUSH_FILE_SUCCESS },
          { type: SystemActions.PENDING_FILE_DELETED, payload: FILE['id'] }
        ]),
        catchError(err => {
          console.error('Push file error:', err);
          return [{ type: SystemActions.PUSH_FILE_FAIL }];
        })
      );
    })
  );

  @Effect()
  pendingFileDelete$ = this.action$.pipe(
    ofType(SystemActions.PENDING_FILE_DELETED),
    mergeMap((action: SystemActions.PendingFileDeleted) =>
      from(this.db.deletePendingFile(action.payload)).pipe(
        mergeMap(() => [{ type: SystemActions.PENDING_FILE_DELETED_SUCCESS, payload: action.payload }]),
        catchError(err => [{ type: SystemActions.PENDING_FILE_DELETED_FAIL, payload: err }])
      )
    )
  );

  @Effect({ dispatch: false })
  pendingOperationUploadFailed$ = this.action$.pipe(
    ofType(SystemActions.UPLOAD_PENDING_OPERATION_FAIL),
    concatMap(action => this.api.sendError(action['payload']))
  );
}
