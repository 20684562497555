import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { SystemActions } from '../root-store';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  @Input() authState: Observable<any>;
  @Input() systemState: Observable<any>;
  @Input() dbState: Observable<any>;

  version = environment.version;

  constructor(private store$: Store<any>) {}

  ngOnInit() {}

  toggleMenu(): void {
    this.store$.dispatch(new SystemActions.ToggleSidebar());
  }
}
