import { Component, ChangeDetectionStrategy, Output, Input } from '@angular/core';
import { MatDialog } from '@angular/material';

import { BehaviorSubject } from 'rxjs';
import { filter, tap, catchError, take } from 'rxjs/operators';

import { ApiService } from 'src/app/services/api.service';
import { CameraDialogComponent } from 'src/app/container/photo-camera/camera-dialog/camera-dialog.component';

@Component({
  selector: 'app-add-photo-button',
  templateUrl: './add-photo-button.component.html',
  styleUrls: ['./add-photo-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddPhotoButtonComponent {
  @Output()
  files$ = new BehaviorSubject([]);

  @Input()
  isDisabled: boolean = false; 


  constructor(public dialog: MatDialog, private api: ApiService) { }

  openPhotoDialog(): void {
    if(this.isDisabled) {
      return
    }
    const dialogRef = this.dialog.open(CameraDialogComponent, {
      width: '80%',
      height: '90vh'
    });

    dialogRef.afterClosed().pipe(
      take(1),
      filter(files => files),
      tap(files => this.files$.next(files)),
      catchError(err => {
        const errMsg = `add.photo-button.ts: ${err.toString()}`;
        console.error(err);
        return this.api.sendError(errMsg);
      })
    ).subscribe();
  }

}
