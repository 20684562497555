import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { StationAction } from 'src/app/root-store/station';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-dashboard-table',
  templateUrl: './dashboard-table.component.html',
  styleUrls: ['./dashboard-table.component.css']
})
export class DashboardTableComponent implements OnInit {
  @Input() data: Array<any>;

  constructor(private store: Store<any>, private db: DbService) {}

  ngOnInit() {}

  editOperation(raw) {
    this.store.dispatch(new StationAction.TryEditUnfinishedOperation(raw));
  }

  deleteOperation(item) {
    if (item.id) {
      this.db.deleteOperation(item.id).then(() => {
        // Delete from view
        const index = this.data.findIndex(
          ({ id_cliente, id_stazione, date_index, operation_type }) =>
            id_cliente === item.id_cliente &&
            id_stazione === item.id_stazione &&
            date_index === item.date_index &&
            operation_type === item.operation_type
        );

        if (index !== -1) {
          this.data.splice(index, 1);
        }
      });
    }
  }
}
