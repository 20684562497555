import { Injectable } from '@angular/core';
import { CanDeactivate, Router, NavigationStart, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { RouterStateService } from './router-state.service';

@Injectable({
  providedIn: 'root'
})
export class BlockNavigationService implements CanDeactivate<any> {
  constructor(private routerStateService: RouterStateService) {}

  canDeactivate(
    component: any,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.routerStateService.inAppNavigation();
  }
}
