import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import * as b64toBlob from 'b64-to-blob';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class ImageManagerService {
  constructor(private sanitizer: DomSanitizer) {}

  getImageInfo(file: File): Observable<any> {
    let promiseResolve;
    const promise = new Promise(res => (promiseResolve = res));
    /*const fileBlob$ = new BehaviorSubject({ blob: new Blob(), coord: null });
    const fileBlob$ = new BehaviorSubject({ blob: new Blob(), coord: null });*/

    // Convert image file to Base64 format : string
    const loadImage = window['loadImage'](
      file,
      function(img, data) {
        let lat, lng;

        if (data.exif) {
          lat = data.exif.get('GPSLatitude');
          lng = data.exif.get('GPSLongitude');
        }

        const coordinates = lat && lng ? { lat: lat, lng: lng } : null;

        const contentType = 'image/jpg';
        const b64 = img.toDataURL();
        const fileDataBlob = b64toBlob.default(b64.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''), contentType);
        promiseResolve({ blob: fileDataBlob, coord: coordinates });
      },
      { maxWidth: 1200, maxHeight: 1200, canvas: true, meta: true, downsamplingRatio: 0.5, orientation: true } // Options
    );

    return from(promise);
  }

  blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('loadend', e => {
        resolve(reader.result as ArrayBuffer);
      });
      reader.addEventListener('error', reject);
      reader.readAsArrayBuffer(blob);
    });
  }

  arrayBufferToBlob(buffer, type): Blob {
    return new Blob([buffer], { type: type });
  }

  // Sanitize url blob for previw image in DOM
  generateSafeUrlFromBlob(file): any {
    if (!file) {
      return false;
    }

    const url = window.URL.createObjectURL(file);
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
