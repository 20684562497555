import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Injectable()
export class TasksService {
  constructor(private fb: FormBuilder) {}

  // Add photo to form
  addPhoto(arrayBuffer, fileType: string): FormGroup {
    return this.fb.group({
      file: [arrayBuffer],
      type: fileType
    });
  }
}
