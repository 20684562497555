import { Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import { ImageManagerService } from 'src/app/services/image-manager.service';

@Component({
  selector: 'app-blob-image',
  templateUrl: './blob-image.component.html',
  styleUrls: ['./blob-image.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlobImageComponent implements OnInit {


  /**
   * Data of image
   *
   * @type {Blob | { file: ArrayBuffer, type: string}}
   * @memberof BlobImageComponent
   */
  @Input()
  photoFile: any;

  @Input()
  arrayBufferFormat: boolean;

  urlSanitized: any;

  constructor(private imageService: ImageManagerService) { }

  ngOnInit() {
    const blob = this.arrayBufferFormat ?
    this.imageService.arrayBufferToBlob(this.photoFile.file, this.photoFile.type) : this.photoFile;
    this.urlSanitized = this.imageService.generateSafeUrlFromBlob(blob);
  }
}
