import { Injectable } from '@angular/core';

import { HashCodeService } from './hash-code.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private hashCodeService: HashCodeService) { }

  signInUser(username: string, password: string) {
    //TODO:
    //Find user in IndexedDB
    //Set in state , result of authetication
  }

  checkCorrectUsernameAndPassword(username: string, password: string, currentHashUsername: string, currentHashPassword: string): Boolean {
    const hashUsername = this.hashCodeService.convertIntoHashCode(username);
    const hashPassword = this.hashCodeService.convertIntoHashCode(password);
    if (hashUsername && hashPassword && currentHashUsername == hashUsername && currentHashPassword == hashPassword) {
      return true;
    }
    return false;
  }

}
