import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { systemReducer } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { SystemEffects } from './effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('system', systemReducer),
    EffectsModule.forFeature([SystemEffects])
  ],
  declarations: []
})
export class SystemModule { }
