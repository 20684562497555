import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { DbService } from '../../services/db.service';
import { concatMap, catchError, mergeMap, tap, map, switchMap, withLatestFrom, pluck } from 'rxjs/operators';
import { DbActions } from '.';
import { AuthAction } from '../auth';
import { ApiService } from '../../services/api.service';
import { forkJoin } from 'rxjs';
import { RootStoreState } from '..';
import { Store } from '@ngrx/store';

@Injectable()
export class DbEffects {
  constructor(
    private action$: Actions,
    private db: DbService,
    private store: Store<RootStoreState.State>,
    private api: ApiService
  ) {}

  @Effect()
  checkDbInstalled$ = this.action$.pipe(
    ofType(DbActions.CHECK_DB_BOOTSTRAP),
    concatMap(() => this.db.isDbInstalled()),
    mergeMap(installed => {
      if (installed) {
        return [
          { type: DbActions.SET_DB_INSTALL_STATUS, payload: installed },
          { type: AuthAction.CHECK_AUTH_STATE_BOOTSTRAP }
        ];
      } else {
        return [{ type: DbActions.SET_DB_INSTALL_STATUS, payload: installed }];
      }
    }),
    catchError(err => [{ type: DbActions.ERROR, payload: 'Failed db.isDbInstalled()' }])
  );

  @Effect()
  initDB$ = this.action$.pipe(
    ofType(DbActions.INIT_DB),
    concatMap(() => this.db.createInstanceDB()),
    mergeMap(() => [{ type: DbActions.TRY_FETCH_LATEST_DB }])
  );

  @Effect()
  tryFetchLatestDB$ = this.action$.pipe(
    ofType(DbActions.TRY_FETCH_LATEST_DB),
    concatMap(() => this.api.fetchDB()),
    mergeMap(dbArray =>
      this.api.getDBVersion().pipe(
        mergeMap(version => [
          {
            type: DbActions.TRY_POPULATE_DB,
            payload: { fetched_data: dbArray, version: version }
          }
        ]),
        catchError(err => [
          { type: DbActions.ERROR, payload: 'API get last version DB error' },
          { type: DbActions.CONTROL_VERSION_FAIL }
        ])
      )
    ),
    catchError(err => [
      { type: DbActions.ERROR, payload: 'API fetch DB error' },
      { type: DbActions.CONTROL_VERSION_FAIL }
    ])
  );

  @Effect()
  tryPopulateDB$ = this.action$.pipe(
    ofType(DbActions.TRY_POPULATE_DB),
    switchMap(action =>
      forkJoin(
        this.db.populateCollectionData(action['payload']['fetched_data'][0], 'smart-pga_users', 'user'),
        this.db.populateStations(action['payload']['fetched_data'][1]),
        this.db.populateCollectionData(action['payload']['fetched_data'][2], 'smart-pga_networks', 'network'),
        this.db.populateCollectionData(action['payload']['fetched_data'][3], 'smart-pga_tools', 'tool'),
        this.db.populateCollectionData(action['payload']['fetched_data'][4], 'smart-pga_tasks', 'task'),
        this.db.populateCollectionData(action['payload']['fetched_data'][5], 'smart-pga_calibrations', 'calibration'),
        this.db.populateCollectionData(action['payload']['fetched_data'][6], 'smart-pga_dataselect', 'dataselect'),
        this.db.populateCollectionData(action['payload']['fetched_data'][7], 'smart-pga_documentstypes', 'documentstypes')
      ).pipe(
        concatMap(() => [
          { type: DbActions.POPULATE_DB_SUCCESS },
          { type: DbActions.UPDATE_DB_VERSION, payload: action['payload']['version'] },
          { type: DbActions.CONTROL_VERSION_SUCCESS },
          { type: DbActions.INSTALL_DB_SUCCESS }
        ]),
        catchError(err => {
          console.error(err);
          return [{ type: DbActions.POPULATE_DB_FAIL }, { type: DbActions.CONTROL_VERSION_FAIL }];
        })
      )
    )
  );

  @Effect()
  installDbSuccess$ = this.action$.pipe(
    ofType(DbActions.INSTALL_DB_SUCCESS),
    withLatestFrom(this.store.select('auth')),
    mergeMap(([action, authState]) => [
      { type: DbActions.SET_DB_INSTALL_STATUS, payload: true },
      { type: DbActions.RESET_DB_ERROR },
      { type: AuthAction.FIND_USER_CREDENTIALS, payload: authState.jwt['username'] },
      {
        type: AuthAction.SET_JWT_TOKEN,
        payload: { token: authState.jwt['token'], username: authState.jwt['username'] }
      }
    ])
  );

  @Effect()
  controlVersion$ = this.action$.pipe(
    ofType(DbActions.CONTROL_VERSION),
    switchMap(() =>
      this.api.getDBVersion().pipe(
        map(response => response.version),
        concatMap(
          versionServer => this.db.getCurrenVersion(),
          (versionServer, versionLocal) => [versionServer, versionLocal]
        ),
        mergeMap(([versionServer, versionLocal]) => {
          if (versionServer > versionLocal) {
            return [{ type: DbActions.TRY_FETCH_LATEST_DB }];
          } else {
            return [{ type: DbActions.CONTROL_VERSION_SUCCESS }];
          }
        }),
        catchError(err => [{ type: DbActions.CONTROL_VERSION_FAIL }])
      )
    )
  );

  @Effect({ dispatch: false })
  updateDBVersion$ = this.action$.pipe(
    ofType(DbActions.UPDATE_DB_VERSION),
    tap(action => this.db.setVersion(action['payload']['version']))
  );

  @Effect({ dispatch: false })
  updateOperation$ = this.action$.pipe(
    ofType(DbActions.UPDATE_OPERATION),
    tap(action => this.db.addOperation(action['payload']))
  )
}
