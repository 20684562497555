import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'byGPSCoord'
})
export class ByGPSCoordPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!args) {
      return value;
    }

    const latitude = args.GPSLatitude;
    const longitude = args.GPSLongitude;
    
    const stations = value.filter(station => station.lati2 == latitude[1].toFixed())
    .filter(station => station.long2 == longitude[1].toFixed())
    .filter(station => Math.abs(station.lati3 - latitude[2].toPrecision()) < 1.2);

    console.log(value);
    console.log(args);
    console.log(stations);
    return stations;
  }

}
