import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { RootStoreState } from '../root-store';
import { AuthState } from '../root-store/auth/state';
import { Observable, from } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { DbService } from './db.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(private store: Store<RootStoreState.State>, private router: Router, private db: DbService) {}

  canActivate(): Observable<boolean> | boolean {
    return this.store.select('auth').pipe(
      first(),
      map((authState: AuthState) => {
        if (!authState.isAuthenticated && !authState.guestMode) {
          this.router.navigateByUrl('/auth');
          return false;
        }

        return true;
      })
    );
  }
}
