import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { Router } from '@angular/router';

import * as StepActions from './actions';
import {
  tap,
  mergeMap,
  map,
  withLatestFrom,
  concatMap,
  switchMap,
  filter,
  catchError
} from 'rxjs/operators';
import { RootStoreState } from '..';
import { DbService } from '../../services/db.service';
import { ModalManagerService } from '../../services/modal-manager.service';
import { StationAction } from '../station';
import { ApiService } from '../../services/api.service';
import { from } from 'rxjs';
import { CameraAction } from '../camera';
import { SystemActions } from '../system';
import { ImageManagerService } from 'src/app/services/image-manager.service';
import { DbActions } from '../db';

@Injectable()
export class StepEffects {
  constructor(
    private action$: Actions,
    private router: Router,
    private store$: Store<RootStoreState.State>,
    private db: DbService,
    private api: ApiService,
    private imageService: ImageManagerService,
    private modalManager: ModalManagerService
  ) { }

  @Effect()
  startOperation$ = this.action$.pipe(
    ofType(StepActions.START_OPERATION),
    map((action: StepActions.StartOperation) => {
      const { id_cliente, id_stazione, operation_type } = action['payload'];
      const dateIndex = `${new Date().getDate()}${new Date().getMonth()}${new Date().getFullYear()}`;
      const id = [id_cliente, id_stazione, dateIndex, operation_type];

      return { ...action['payload'], id: id, date_index: dateIndex };
    }),
    mergeMap(stationData =>
      from(
        this.db.getOperation([
          stationData.id_cliente,
          stationData.id_stazione,
          stationData.date_index,
          stationData.operation_type
        ])
      ).pipe(
        mergeMap(operation => [
          { type: StationAction.SET_DATA, payload: stationData },
          stationData.operation_type === 'extra' ?
            { type: StepActions.SET_EXTRA_STEP_LIST } :
            { type: StepActions.SET_COMMON_STEP_LIST },
          operation
            ? { type: StepActions.OPERATION_EXIST, payload: operation }
            : { type: StepActions.OPERATION_NEW }
        ]),
        catchError(err => {
          console.error(err);
          return err;
        })
      )
    )
  );

  @Effect()
  operationNew$ = this.action$.pipe(
    ofType(StepActions.OPERATION_NEW),
    withLatestFrom(this.store$.select('station'), this.store$.select('auth')),
    map(([action, stationState, authState]) => {
      const NEGATIVE_KEYS = [
        'data_inserimento_nota',
        'lati1',
        'lati2',
        'lati3',
        'latiacc1',
        'latiacc2',
        'latiacc3',
        'long1',
        'long2',
        'long3',
        'longacc1',
        'longacc2',
        'longacc3'
      ];
      const NEW_OPERATION = {
        ...stationState.data,
        username: authState.credentials.username,
        id_operatore: authState.credentials.id_operatore,
        id_by_server: null
      };

      NEGATIVE_KEYS.forEach(item => delete NEW_OPERATION[item]);
      return NEW_OPERATION;
    }),
    switchMap(stepData =>
      this.api.getBlobFile(stepData['start_photo']).pipe(
        concatMap(
          blob => this.imageService.blobToArrayBuffer(blob),
          (blob, arrayBuffer) => ({ type: blob.type, file: arrayBuffer })
        ),

        tap(({ type, file }) => (stepData['photos']['start'] = [{ file: file, type: type }])),
        mergeMap(() =>
          from(this.db.addOperation(stepData)).pipe(
            mergeMap(() => [
              { type: StepActions.OPERATION_ADDED_SUCCESSFULY },
              { type: StepActions.START_NAVIGATION_TO_STEP, payload: 'notification' },
              { type: StationAction.DELETE_PHOTO_BEFORE },
              { type: StepActions.DELETE_PENDING_OPERATION, payload: stepData.id }
            ]),
            catchError(err => {
              console.error(err);
              return [{ type: StepActions.OPERATION_ADDED_FAIL }, { type: StepActions.NAVIGATE_TO_DASHBOARD }];
            })
          )
        ),
        catchError(err => {
          console.error(err);
          return [{ type: StepActions.GET_IMAGE_FROM_BLOB_FAIL }, { type: StepActions.NAVIGATE_TO_DASHBOARD }];
        })
      )
    )
  );

  @Effect()
  operationExist$ = this.action$.pipe(
    ofType(StepActions.OPERATION_EXIST),
    switchMap((action: StepActions.OperationNew) => this.modalManager.openOverwriteModal()),
    mergeMap((overwrite: boolean) => {
      if (overwrite) {
        return [{ type: StepActions.OPERATION_NEW }];
      } else {
        return [{ type: StepActions.NAVIGATE_TO_DASHBOARD }];
      }
    })
  );

  @Effect()
  startNavigation$ = this.action$.pipe(
    ofType(StepActions.START_NAVIGATION_TO_STEP),
    map(action => action['payload']),
    withLatestFrom(this.store$.select('step')),
    map(([stepName, stepState]) => {
      let stepIndex = 0;
      if (stepState.list.indexOf(stepName) < 0) {
        stepName = stepState.list[stepState.index + 1] as never;
        stepIndex = stepState.index + 1;
      } else {
        stepIndex = stepState.list.indexOf(stepName);
      }

      this.router.navigate(['/app/operation/step/' + stepName]);
      return [stepName, stepIndex];
    }),
    mergeMap(([stepName, stepIndex]) => [
      { type: StepActions.SET_NAME, payload: stepName },
      { type: StepActions.SET_INDEX, payload: stepIndex }
    ])
  );

  @Effect()
  completeOnlineNotification$ = this.action$.pipe(
    ofType(StepActions.COMPLETE_NOTIFICATION),
    withLatestFrom(this.store$.select('station')),
    concatMap(([action, store]) => this.db.insertNotification(store.data.id, action['payload'])),
    mergeMap(() => [{ type: StepActions.START_NAVIGATION_TO_STEP, payload: 'request' }])
  );

  @Effect()
  tryApplyStep$ = this.action$.pipe(
    ofType(StepActions.TRY_APPLY_STEP),
    withLatestFrom(this.store$.select('station')),
    concatMap(([{ payload: { data, tableName, navigateTo } }, store]) =>
      from(this.db.insertDataToDB(store.data.id, data, tableName)).pipe(
        mergeMap(() => [{ type: StepActions.START_NAVIGATION_TO_STEP, payload: navigateTo }]),
        catchError(err => [err])
      ))
  );

  @Effect({ dispatch: false })
  tryApplyDraftTasks$ = this.action$.pipe(
    ofType(StepActions.TRY_APPLY_DRAFT_TASKS),
    withLatestFrom(this.store$.select('station')),
    concatMap(([action, store]) => this.db.insertTasksDraft(store.data.id, action['payload'])),
    catchError(err => err)
  );

  @Effect({ dispatch: false })
  tryApplyWarehouse$ = this.action$.pipe(
    ofType(StepActions.TRY_APPLY_WAREHOUSE),
    withLatestFrom(this.store$.select('station')),
    concatMap(([action, store]) => this.db.insertWarehouse(store.data.id, action['payload'])),
    catchError(err => err)
  );

  @Effect()
  finishOperation$ = this.action$.pipe(
    ofType(StepActions.FINISH_OPERATION),
    withLatestFrom(this.store$.select('station')),
    map(([action, store]) => {
      const date_end = new Date().getTime();
      // Duration operation in minute
      const duration_operation = Math.floor((date_end - store.data.date_start) / 60000);
      const updatedStore = {
        ...store,
        finish: {
          ...(action['payload'] as {}),
          date_end: date_end,
          duration_operation: duration_operation,
          send_operation: true
        }
      };
      return updatedStore;
    }),
    concatMap(store => this.db.insertDataToDB(store.data.id, store.finish, 'finish'), store => store),
    mergeMap(store => [
      { type: StepActions.FINISH_OPERATION_SUCCESS },
      { type: StepActions.ADD_PENDING_OPERATION, payload: store.data.id },
      { type: StepActions.TRY_UPLOAD_OPERATION, payload: store.data.id }
    ])
  );

  @Effect()
  tryUploadOperation$ = this.action$.pipe(
    ofType(StepActions.TRY_UPLOAD_OPERATION),
    withLatestFrom(this.store$.select('system')),
    filter(([action, systemState]) => systemState.online),
    concatMap(([action, systemState]) => this.db.getOperation(action['payload'])),
    map(operation => {
      const requestsId: any[] = operation['request_id'];

      if (requestsId !== null && requestsId.length > 0 && operation['requests']) {
        const request = operation['requests'].find(_req => _req.id === operation['request_id']);
        operation['request'] = request;
      }
      if ( requestsId === null || (requestsId !== null && requestsId.length === 0)) {
        operation['request'] = null;
      }
      return operation;
    }),
    switchMap(operation =>
      this.api.uploadOperation(operation).pipe(
        mergeMap(response => {

          const operationWithoutPhotos = this.removePhotos({ ...operation, id_by_server: response.id });

          return [
            { type: DbActions.UPDATE_OPERATION, payload: operationWithoutPhotos },
            { type: StepActions.OPERATION_UPLOADED_SUCCESSFULY },
            { type: StepActions.DELETE_PENDING_OPERATION, payload: operation.id },
            { type: SystemActions.TRY_DELETE_UPLOADED_OPERATION_REQUEST, payload: operation },
            { type: SystemActions.SAVE_FILES, payload: [operation, response['id']] }
          ];
        }),
        catchError(err => {
          console.log('Error:', err);
          return [{ type: StepActions.OPERATION_UPLOADED_FAIL }];
        })
      )
    )
  );

  @Effect()
  addOperationToPending$ = this.action$.pipe(
    ofType(StepActions.ADD_PENDING_OPERATION),
    concatMap((action: StepActions.AddPendingOperation) => this.db.addPendingOperation(action.payload)),
    mergeMap(() => [
      { type: StepActions.ADD_PENDING_OPERATION_SUCCESS },
      { type: StepActions.NAVIGATE_TO_DASHBOARD },
      { type: StationAction.RESET_DATA },
      { type: StepActions.RESET_STATE }
    ])
  );

  @Effect()
  deletePendingOperation$ = this.action$.pipe(
    ofType(StepActions.DELETE_PENDING_OPERATION),
    concatMap((action: StepActions.AddPendingOperation) => this.db.deletePendingOperation(action.payload)),
    mergeMap(() => [{ type: StepActions.DELETE_PENDING_OPERATION_SUCCESS }])
  );

  @Effect()
  navigateToDashboard$ = this.action$.pipe(
    ofType(StepActions.NAVIGATE_TO_DASHBOARD),
    tap(() => this.router.navigate(['/app'])),
    mergeMap(() => [{ type: CameraAction.STOP }])
  );

  private removePhotos(operation: any): any {
    const photos = { ...operation.photos, calibration:[], finish: [], operators: [], request: [], start: [],  tools: [] };

    return { ...operation, photos };
  }
}
