import { Component, OnInit} from '@angular/core';
import { MatDialogRef} from '@angular/material';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { RootStoreState, AuthAction } from '../../root-store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-auth-dialog',
  templateUrl: './auth-dialog.component.html',
  styleUrls: ['./auth-dialog.component.css']
})
export class AuthDialogComponent implements OnInit {
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AuthDialogComponent>, 
    private fb: FormBuilder,
    private store: Store<RootStoreState.State>
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });    
  }

  getLoginFormDate():{ username: string, password: string } {
    const username: string = this.form.get("username").value;
    const password: string = this.form.get("password").value;

    username.trim();
    password.trim();

    const payload = {
      username: username,
      password: password
    };

    return payload;
  }

  auth() {
    const loginData = this.getLoginFormDate();
    this.store.dispatch(new AuthAction.TryAuthenticate(loginData));
    this.dialogRef.close();
  }
}


