import { initialState } from './state';
import * as Actions from './actions';

export function cameraReducer(state = initialState, action: Actions.CameraActions) {
    switch (action.type) {
        case Actions.ACTIVATE:
            return {
                ...state,
                live: true,
                permissionAllowed: true,
            };
        case Actions.DEACTIVATE:
            return {
                ...state,
                live: false,
                flip: false,
                permissionAllowed: true,
            };
        case Actions.PERMISSION_DENY:
            return {
                ...state,
                live: false,
                permissionAllowed: false,
            };
        case Actions.FLIP:
            const toggledFlip = !state.flip;
            return {
                ...state,
                flip: toggledFlip
            };
        case Actions.SET_STREAMING_TRACK:
            return {
                ...state,
                stream: action.payload
            };
        case Actions.SET_STREAM_DIMENSION:
            return {
                ...state,
                streamDimension: { ...action.payload }
            };
        case Actions.CONNECTING:
            return {
                ...state,
                connecting: true
            };
        case Actions.DISCONNECTING:
            return {
                ...state,
                connecting: false
            };
        case Actions.START_RECORDING:
            return {
                ...state,
                recording: true
            };
        case Actions.STOP_RECORDING:
            return {
                ...state,
                recording: false
            };
        case Actions.SET_ERROR:
            return {
                ...state,
                error: action.payload
            };
        case Actions.RESET_ERROR:
            return {
                ...state,
                error: null
            };
        case Actions.SET_PHOTO_BLOB_URL:
            return {
                ...state,
                urlBlob: action.payload
            };
        case Actions.SET_PHOTO_BLOB_FILE:
            return {
                ...state,
                fileBlob: action.payload
            };
        default:
            return state;
    }
}
