import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, concatMap } from 'rxjs/operators';
import { DbService } from './db.service';

@Injectable({
  providedIn: 'root'
})
export class JWTGuardService implements CanActivate {

  constructor(private db: DbService) { }

  canActivate(): Observable<boolean> | boolean {
    return of(null)
    .pipe(
      concatMap(() => this.db.getJWT()),
      map(jwt =>  {
        if (jwt) return jwt.length < 1;
        return true;
      })
    );
  }
}
