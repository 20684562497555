import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-request-card',
  templateUrl: './request-card.component.html',
  styleUrls: ['./request-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestCardComponent implements OnInit {

  firstUpdate: boolean = true;

  @Input() request: any;
  @Input() button: boolean;
  @Input() isSelected: boolean = false;
  @Input() selectedRequests: any[];

  @Output() selectedRequest = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  startRequest(request): void {
    this.selectedRequest.emit(this.request);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedRequests && this.firstUpdate) {
      for(let i = 0; i < changes.selectedRequests.currentValue.length; i++){
        if (changes.selectedRequests.currentValue[i].id === this.request.id) {
          this.isSelected = true;
        }
      }
      this.firstUpdate = false;
    }
  }
}
