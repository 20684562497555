import { Injectable } from '@angular/core';
import { fromEvent, Subject, from, of } from 'rxjs';
import { tap, skip, first, map, reduce, mergeMap, delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FileManagerService {
  fileManagerHandler$ = new Subject();
  fileManagerHTMLElement: HTMLInputElement;
  inputID = 'fileManager';

  constructor() {}

  uploadImage(): Subject<any> {
    this.compileInput();
    this.manageEvents();
    return this.fileManagerHandler$;
  }

  /**
   * @description Insert to the DOM new input element, which will keep uploaded document or image
   */
  compileInput(): void {
    const offsetTop = window.scrollY;

    if (document.getElementById(this.inputID)) {
      document.getElementById(this.inputID).remove();
    }

    this.fileManagerHTMLElement = document.createElement('input');
    this.fileManagerHTMLElement.id = this.inputID;
    this.fileManagerHTMLElement.type = 'file';
    this.fileManagerHTMLElement.accept = 'image/*';
    this.fileManagerHTMLElement.multiple = true;
    document.body.appendChild(this.fileManagerHTMLElement);
    this.fileManagerHTMLElement.click();
    this.fileManagerHTMLElement.focus();

    // Fix workaround on issue, when focus on input hidden , auto scroll to top of page.
    // In this way we store position of scroll before open File manager, and after turn back to
    // this position

    window.scroll(0, offsetTop);
  }

  /**
   * @description Manage blur event, which use on cancel fileManager. Manage change event for extract file
   */
  manageEvents(): void {
    const change$ = fromEvent(this.fileManagerHTMLElement, 'change');
    const cancel$ = fromEvent(this.fileManagerHTMLElement, 'blur');

    // On cancel fileManager, delete input element
    cancel$
      .pipe(
        skip(1),
        first(),
        tap(() => this.fileManagerHTMLElement.remove())
      )
      .subscribe();

    // On change fileManager, extract file
    change$
      .pipe(
        first(),
        map(event => event.target['files']),
        map(fileList => Object.keys(fileList).map(key => fileList[key])),
        tap(files => this.fileManagerHandler$.next(files))
      )
      .subscribe();
  }
}
