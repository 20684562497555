import { Action } from '@ngrx/store';

export const START = '[Station Page] Start Camera';

export const STOP = '[Camera component] Stop';
export const FLIP = '[Camera component] Flip';
export const MAKE_PHOTO = '[Camera component] Make Photo';
export const SET_PHOTO_BLOB_URL = '[Camera component] Set captured photo blob url';
export const SET_PHOTO_BLOB_FILE = '[Camera component] Set captured photo blob file';

export const STREAMING_SUCCESS = '[Camera Manager] Streaming Started Successfully';
export const START_STREAMING = '[Camera Manager] Start Streaming';
export const SET_STREAMING_TRACK = '[Camera Manager] Set Streaming Track';
export const DELETE_STREAMING_TRACK = '[Camera Manager] Delete Streaming Track';
export const SET_STREAM_DIMENSION = '[Camera Manager] Set Stream Dimension';
export const CAPTURE_SNAPSHOT = '[Camera Manager] Capture Snapshot';
export const SNAPSHOT_CAPTURED_SUCCESS = '[Camera Manager] Snapshot Captured Successfuly';

export const CONNECTING = '[Camera] Establish connection with camera';
export const DISCONNECTING = '[Camera] Disconnecting browser camera';
export const COMPILE_STREAM = '[Camera] Compile Media Stream';
export const START_RECORDING = '[Camera] Start recording stream for snapshot';
export const STOP_RECORDING = '[Camera] Stop recording stream for snapshot';
export const ACTIVATE = '[Camera] Activate';
export const DEACTIVATE = '[Camera] Deactivate';
export const PERMISSION_DENY = '[Camera] Permission Deny';
export const PERMISSION_ALLOW = '[Camera] Permission Allow';

export const SET_ERROR = '[Camera] Set error message';
export const RESET_ERROR = '[Camera] RESET error message';

export class StartCamera implements Action {
    readonly type = START;
}

export class Connecting implements Action {
    readonly type = CONNECTING;
}

export class Disconnecting implements Action {
    readonly type = DISCONNECTING;
}

export class CompileStream implements Action {
    readonly type = COMPILE_STREAM;
}

export class StartRecording implements Action {
    readonly type = START_RECORDING;
}

export class StopRecording implements Action {
    readonly type = STOP_RECORDING;
}

export class StopCamera implements Action {
    readonly type = STOP;
}

export class FlipCamera implements Action {
    readonly type = FLIP;
}

export class MakePhoto implements Action {
    readonly type = MAKE_PHOTO;
}

export class SetPhotoBlob implements Action {
    readonly type = SET_PHOTO_BLOB_URL;
    constructor (public payload: any) {}
}

export class SetPhotoBlobFile implements Action {
    readonly type = SET_PHOTO_BLOB_FILE;
    constructor (public payload: any) {}
}

export class CaptureSnapshot implements Action {
    readonly type = CAPTURE_SNAPSHOT;
}

export class SnapshotCapturedSuccess implements Action {
    readonly type = SNAPSHOT_CAPTURED_SUCCESS;
}

export class ActivateCamera implements Action {
    readonly type = ACTIVATE;
}

export class StreamingSuccess implements Action {
    readonly type = STREAMING_SUCCESS;
}

export class SetStreamingTrack implements Action {
    readonly type = SET_STREAMING_TRACK;
    constructor (public payload: any) {}
}

export class DeleteStreamingTrack implements Action {
    readonly type = DELETE_STREAMING_TRACK;
}


export class SetStreamDimension implements Action {
    readonly type = SET_STREAM_DIMENSION;
    constructor (public payload: any) {}
}

export class DeactivateCamera implements Action {
    readonly type = DEACTIVATE;
}

export class PermissionDeny implements Action {
    readonly type = PERMISSION_DENY;
}

export class PermissionAllow implements Action {
    readonly type = PERMISSION_ALLOW;
}

export class SetError implements Action {
    readonly type = SET_ERROR;
    constructor(public payload: string) {}
}
export class ResetError implements Action {
    readonly type = RESET_ERROR;
}

export type CameraActions = StartCamera | Connecting | Disconnecting | CompileStream | StartRecording | StopRecording | StopCamera |
    FlipCamera | MakePhoto | SetPhotoBlob | SetPhotoBlobFile | CaptureSnapshot | SnapshotCapturedSuccess | ActivateCamera |
    DeactivateCamera | PermissionDeny | PermissionAllow | StreamingSuccess |
    SetStreamingTrack | DeleteStreamingTrack | SetStreamDimension | SetError | ResetError;
