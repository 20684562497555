import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import * as StationAction from './actions';
import * as StepAction from '../step/actions';
import { DbService } from '../../services/db.service';
import { concatMap, mergeMap, withLatestFrom, switchMap, map } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import { StepActions } from '../step';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';

@Injectable()
export class StationEffects {
  constructor(private action$: Actions, private db: DbService, private api: ApiService, private store$: Store<any>) {}

  @Effect()
  syncStations$ = this.action$.pipe(
    ofType(StationAction.SYNC_STATIONS),
    concatMap(() => this.db.getTableData('smart-pga_stations', 'station')),
    mergeMap(stations => [
      { type: StationAction.SET_STATIONS, payload: stations },
      { type: StationAction.EXTRACT_REQUESTS, payload: stations }
    ])
  );

  @Effect()
  syncNetworks$ = this.action$.pipe(
    ofType(StationAction.SYNC_NETWORKS),
    concatMap(() => this.db.getTableData('smart-pga_networks', 'network')),
    mergeMap(networks => [{ type: StationAction.SET_NETWORKS, payload: networks }])
  );

  @Effect()
  extractRequests$ = this.action$.pipe(
    ofType(StationAction.EXTRACT_REQUESTS),
    map(action => {
      const stations = action['payload'] as Array<any>;

      const _requests = stations
        .map(({ requests, nome, id_rete }) => ({ name: nome, requests: requests, id_rete: id_rete }))
        .filter(el => el.requests);

      const flat = _requests.reduce((acc, cur) => {
        cur.requests.forEach(_req => {
          acc.push({ ..._req, station_name: cur.name, id_rete: cur.id_rete });
        });
        return acc;
      }, []);

      return flat;
    }),
    mergeMap(requests => [{ type: StationAction.SET_REQUESTS, payload: requests }])
  );

  @Effect()
  tryNotify$ = this.action$.pipe(
    ofType(StationAction.TRY_NOTIFY),
    concatMap(() => this.api.notify()),
    mergeMap(status => {
      if (status === 200) {
        return [
          { type: StationAction.ONLINE_NOTIFICATION_SUCCESS },
          { type: StepAction.COMPLETE_NOTIFICATION, payload: 'onlineSuccess' }
        ];
      } else {
        return [{ type: StationAction.ONLINE_NOTIFICATION_FAIL }];
      }
    })
  );

  @Effect()
  manualNotificationSuccess$ = this.action$.pipe(
    ofType(StationAction.MANUAL_NOTIFICATION_SUCCESS),
    mergeMap(() => [{ type: StepAction.COMPLETE_NOTIFICATION, payload: 'manualSuccess' }])
  );

  @Effect()
  manualNotificationFail$ = this.action$.pipe(
    ofType(StationAction.MANUAL_NOTIFICATION_FAIL),
    mergeMap(() => [{ type: StepAction.COMPLETE_NOTIFICATION, payload: 'manualFail' }])
  );

  @Effect()
  tryEditUnfinishedOperation$ = this.action$.pipe(
    ofType(StationAction.TRY_EDIT_OPERATION),
    mergeMap((action: StationAction.TryEditUnfinishedOperation) => {
      const data = action.payload;
      const operationType = data['operation_type'];
      let urlRoute = 'tools';

      if (operationType === 'common') {
        if (data['step_request']) {
          urlRoute = 'request';
        }
        if (data['step_tools']) {
          urlRoute = 'tools';
        }
        if (data['step_tasks']) {
          urlRoute = 'tasks';
        }
        if (data['step_calibrations']) {
          urlRoute = 'calibration';
        }
        if (data['step_operators']) {
          urlRoute = 'operators';
        }
      } else {
        if (data['step_request']) {
          urlRoute = 'request';
        }
        if (data['step_tools']) {
          urlRoute = 'tools';
        }
        if (data['step_calibrations']) {
          urlRoute = 'calibration';
        }
        if (data['step_operators']) {
          urlRoute = 'operators';
        }
      }

      return [
        { type: StationAction.POPULATE_OPERATION_DATA, payload: action.payload },
        {
          type:
            action.payload.operation_type === 'extra'
              ? StepActions.SET_EXTRA_STEP_LIST
              : StepActions.SET_COMMON_STEP_LIST
        },
        { type: StepAction.START_NAVIGATION_TO_STEP, payload: urlRoute }
      ];
    })
  );

  @Effect()
  tryEditStep$ = this.action$.pipe(
    ofType(StationAction.TRY_EDIT_STEP),
    withLatestFrom(this.store$.select('station')),
    switchMap(([action, state]) => {
      const URL = action['payload'];
      const operationId = state['data']['id'];
      return from(this.db.getOperation(operationId)).pipe(
        mergeMap(data => [
          { type: StationAction.POPULATE_OPERATION_DATA, payload: data },
          { type: StepAction.START_NAVIGATION_TO_STEP, payload: URL }
        ])
      );
    })
  );
}
