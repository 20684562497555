export interface SystemState {
  online: boolean; // Interent connection
  gpsOnline: boolean; // State of GPS, not use in current verdion
  sidebar: boolean; // Sidebar is opened
}

export const initialState: SystemState = {
  online: true,
  gpsOnline: true,
  sidebar: false
};
