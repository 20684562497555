import { Injectable } from '@angular/core';
import * as CameraActions from '../root-store/camera/actions';
@Injectable({
  providedIn: 'root'
})
export class CameraManagerService {

  constructor() { }

  /**
   * @description Connect to camera and get access to video stream
   * @return {stream}
   */
  connectCamera() : Promise<any> {
    return navigator.mediaDevices.getUserMedia({
      video: {
        width:2600,
        height: 1440
    }});
  }

  /**
   * @description Set stream to video element and play it
   * @param stream 
   */
  renderVideoStream(stream):HTMLVideoElement {   
    const streamingBox = document.getElementById('streamingBox');

    const videoEl = document.createElement('video');         
    videoEl.setAttribute('style','position: absolute; top: 0; left: 0; width: 100%;height: 100%; z-index: 0;');
    videoEl.id = 'videoStream';
    videoEl.srcObject = stream; 
    streamingBox.appendChild(videoEl); 
    
    return videoEl;
  }

  /**
   * @description Take snapshot from video stream
   * @return { string } image in fromat base64
   */
  captureSnapshot(videoH: number, videoW: number, flip: boolean):string {
    const canvas = document.createElement('canvas');
    const VIDEO_STREAM = <HTMLVideoElement> document.getElementById('videoStream');

    canvas.width = videoW; 
    canvas.height = videoH;

    const context = canvas.getContext('2d');
    
    //Rotate canvas to 180 deg
    if (flip) {
      context.setTransform(1, 0, 0, 1, 0, 0);
      context.translate(canvas.width * 0.5, canvas.height * 0.5);
      context.rotate(180 * Math.PI / 180);
      context.translate(-canvas.width * 0.5, -canvas.height * 0.5);
    } 

    //Capture video to canvas
    context.drawImage(VIDEO_STREAM, 0, 0, videoW, videoH);

    return canvas.toDataURL('image/jpg');
  }
}
