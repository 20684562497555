import { Action } from "@ngrx/store";

export const CHECK_DB_BOOTSTRAP = '[IndexedDB] Check app bootstrap if DB was installed';
export const SET_DB_INSTALL_STATUS = '[DB State] Set DB install status';
export const RESET_DB_ERROR = '[DB State] Reset DB errors'

export const TRY_INSTALL_DB = '[IndexedDB] Try Install DB';
export const INSTALL_DB_SUCCESS = '[IndexedDB] Install DB success';

export const INIT_DB = '[IndexDB] Init Smart-pga DB and create ObjectStores';
export const TRY_FETCH_LATEST_DB = '[IndexedDB] Try fetch latest DB to IndexedDB';

export const TRY_POPULATE_DB = '[IndexedDB] Try populate DB';
export const POPULATE_DB_SUCCESS = '[IndexedDB] Populate DB success';
export const POPULATE_DB_FAIL = '[ERR: IndexedDB] Populate DB failed';

export const CONTROL_VERSION = '[Bootstrap] Control DB version for upgrade to latest version';
export const CONTROL_VERSION_SUCCESS = '[IndexedDB] Control DB version for upgrade to latest version success';
export const CONTROL_VERSION_FAIL = '[IndexedDB] Control DB version for upgrade to latest version fail';
export const CONTROL_VERSION_RESET = '[DB State] Control DB version for upgrade to latest version';


export const UPDATE_DB_VERSION = '[IndexedDB] Update DB version';

export const RESET_STATE = '[DB State] Reset state';
export const ERROR = '[IndexedDB ERROR]';

export const UPDATE_OPERATION = '[IndexedDB] Update an operation';

export class CheckDbBootstrap implements Action {
    readonly type = CHECK_DB_BOOTSTRAP;
}

export class SetDbInstallStatus implements Action {
    readonly type = SET_DB_INSTALL_STATUS;
    constructor(public payload: boolean) {}
}

export class UpdateDbVersion implements Action {
    readonly type = UPDATE_DB_VERSION;
    constructor(public payload: any) {}
}

export class ResetDbError implements Action{
    readonly type = RESET_DB_ERROR;
}

export class InitDb implements Action {
    readonly type = INIT_DB;
}

export class ControlVersionSuccess implements Action {
    readonly type = CONTROL_VERSION_SUCCESS;
}

export class ControlVersionFail implements Action {
    readonly type = CONTROL_VERSION_FAIL;
}

export class ResetDbState implements Action {
    readonly type = RESET_STATE;
}

export class Error implements Action {
    readonly type = ERROR;
}

export class UpdateOperation implements Action {
    readonly type = UPDATE_OPERATION;
    constructor(public payload: any) {}
}

export type DbActions = CheckDbBootstrap | SetDbInstallStatus 
    | UpdateDbVersion | ResetDbError | InitDb 
    | ControlVersionSuccess | ControlVersionFail 
    | Error | ResetDbState | UpdateOperation;