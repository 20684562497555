import { Action } from '@ngrx/store';

export const SET_PHOTO_BEFORE = 'SET_PHOTO_BEFORE';
export const DELETE_PHOTO_BEFORE = 'DELETE_PHOTO_BEFORE';

export const FIND_BY_COORD = 'FIND_BY_COORD';
export const SET_GPS_COORD = 'SET_GPS_COORD';
export const ACTIVATE_GPS = 'ACTIVATE_GPS';
export const SET_COMON_INFO = 'SET_COMON_INFO';

export const SET_DATA = 'SET_DATA';
export const RESET_DATA = 'RESET_DATA';

export const SYNC_STATIONS = '[IndexedDB] Sync stations from IndexedDB with app';
export const SYNC_NETWORKS = '[IndexedDB] Sync networks from IndexedDB with app';

export const EXTRACT_REQUESTS = '[Bootstrap] Extract requests from stations list';
export const SET_REQUESTS = '[Bootstrap] Set requests for stations list';

export const SET_STATIONS = '[Container component] Set stations list successfuly';
export const SET_NETWORKS = '[Container component] Set networks list successfuly';

export const TRY_NOTIFY = '[Notification page] Click button Notify';

export const ONLINE_NOTIFICATION_SUCCESS = '[Notification component] Notification online successed';
export const ONLINE_NOTIFICATION_FAIL = '[Notification component] Notification online failed';

export const MANUAL_NOTIFICATION_SUCCESS = '[Notification page] Click button <Notification by cell phone DONE>';
export const MANUAL_NOTIFICATION_FAIL = '[Notification page] Click button <Notification by cell phone FAILED>';

export const SET_REQUEST_ID = '[Request component] Set request id';

export const TRY_EDIT_OPERATION = '[Edit] Try edit unfinished operation';
export const POPULATE_OPERATION_DATA = '[Edit] Populate operation data to state';

export const TRY_EDIT_STEP = '[Edit] Try edit step';

export const DELETE_UPLOADED_OPERATION_REQUEST =
  '[Pending operation: IndexedDB] Delete uploaded operation request from station';
export const DELETE_UPLOADED_OPERATION_REQUEST_SUCCESS =
  '[Pending operation: IndexedDB] Delete uploaded operation request from station success';

export class SetPhotoBefore implements Action {
  readonly type = SET_PHOTO_BEFORE;
  constructor(public payload: Blob) {}
}

export class DeletePhotoBefore implements Action {
  readonly type = DELETE_PHOTO_BEFORE;
}

export class FindByCoord implements Action {
  readonly type = FIND_BY_COORD;
  constructor(public payload: any) {}
}

export class SetGpsCoord implements Action {
  readonly type = SET_GPS_COORD;
  constructor(public payload: any) {}
}

export class ActivateGps implements Action {
  readonly type = ACTIVATE_GPS;
}

export class SetComonInfo implements Action {
  readonly type = SET_COMON_INFO;
  constructor(public payload: any) {}
}

export class SetData implements Action {
  readonly type = SET_DATA;
  constructor(public payload: any) {}
}

export class ResetData implements Action {
  readonly type = RESET_DATA;
}

export class SyncStations implements Action {
  readonly type = SYNC_STATIONS;
}

export class SetStations implements Action {
  readonly type = SET_STATIONS;
  constructor(public payload: any) {}
}

export class SyncNetworks implements Action {
  readonly type = SYNC_NETWORKS;
}

export class SetNetworks implements Action {
  readonly type = SET_NETWORKS;
  constructor(public payload: any) {}
}

export class ExtractRequests implements Action {
  readonly type = EXTRACT_REQUESTS;
  constructor(public payload: any) {}
}

export class SetRequests implements Action {
  readonly type = SET_REQUESTS;
  constructor(public payload: any) {}
}
export class TryNotify implements Action {
  readonly type = TRY_NOTIFY;
}

export class OnlineNotificationSuccess implements Action {
  readonly type = ONLINE_NOTIFICATION_SUCCESS;
}

export class OnlineNotificationFail implements Action {
  readonly type = ONLINE_NOTIFICATION_FAIL;
}

export class ManualNotificationSuccess implements Action {
  readonly type = MANUAL_NOTIFICATION_SUCCESS;
}

export class ManualNotificationFail implements Action {
  readonly type = MANUAL_NOTIFICATION_FAIL;
}

export class SetRequestId implements Action {
  readonly type = SET_REQUEST_ID;
  constructor(public payload: any) {}
}

export class TryEditUnfinishedOperation implements Action {
  readonly type = TRY_EDIT_OPERATION;
  constructor(public payload: any) {}
}

export class PopulateOperationData implements Action {
  readonly type = POPULATE_OPERATION_DATA;
  constructor(public payload: any) {}
}

export class TryEditStep implements Action {
  readonly type = TRY_EDIT_STEP;
  constructor(public payload: any) {}
}

export class DeleteUploadedOperationRequest implements Action {
  readonly type = DELETE_UPLOADED_OPERATION_REQUEST;
  constructor(public payload: any) {}
}

export class DeleteUploadedOperationRequestSuccess implements Action {
  readonly type = DELETE_UPLOADED_OPERATION_REQUEST_SUCCESS;
  constructor(public payload: any) {}
}

export type StationActions =
  | SetPhotoBefore
  | SetData
  | ResetData
  | SyncStations
  | SetStations
  | SyncNetworks
  | SetNetworks
  | ExtractRequests
  | SetRequests
  | DeletePhotoBefore
  | FindByCoord
  | SetGpsCoord
  | ActivateGps
  | SetComonInfo
  | OnlineNotificationFail
  | OnlineNotificationSuccess
  | ManualNotificationFail
  | ManualNotificationSuccess
  | SetRequestId
  | TryEditUnfinishedOperation
  | PopulateOperationData
  | TryEditStep
  | DeleteUploadedOperationRequest
  | DeleteUploadedOperationRequestSuccess;
