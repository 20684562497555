import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { AuthComponent } from '../auth/auth.component';
import { ContainerComponent } from '../container/container.component';
import { AuthGuardService } from '../services/auth-guard.service';
import { JWTGuardService } from '../services/jwt-guard.service';
import { DashboardComponent } from '../container/dashboard/dashboard.component';
import { GuestComponent } from '../guest/guest.component';
import { UsersResolverService } from '../services/users-resolver.service';
import { BlockNavigationService } from '../services/block-navigation.service';
import { RequestsListComponent } from '../container/requests-list/requests-list.component';
import { DocumentsListComponent } from '../container/documents-list/documents-list.component';

const routes: Routes = [
  { path: '', redirectTo: '/app', pathMatch: 'full' },
  { path: 'auth', component: AuthComponent, canActivate: [JWTGuardService] },
  {
    path: 'guest',
    component: GuestComponent,
    canDeactivate: [BlockNavigationService],
    resolve: {
      users: UsersResolverService
    }
  },
  {
    path: 'app',
    component: ContainerComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [BlockNavigationService],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent, canDeactivate: [BlockNavigationService] },
      { path: 'requests', component: RequestsListComponent, canDeactivate: [BlockNavigationService] },
      { path: 'operation', loadChildren: '../container/operation/operation.module#OperationModule' },
      { path: 'documents', component: DocumentsListComponent, canDeactivate: [BlockNavigationService] }
    ]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  declarations: []
})
export class RoutingModule {}
