import { SystemState, SystemActions } from './';
import * as Actions from './actions';

export function systemReducer(state = SystemState.initialState, action: SystemActions.SystemActions) {
  switch (action.type) {
    case Actions.ONLINE:
      return {
        ...state,
        online: true
      };
    case Actions.OFFLINE:
      return {
        ...state,
        online: false
      };
    case Actions.CLOSE_SIDEBAR:
      return {
        ...state,
        sidebar: false
      };
    case Actions.OPEN_SIDEBAR:
      return {
        ...state,
        sidebar: true
      };
    case Actions.TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebar: !state.sidebar
      };
    /*
        case Actions.FETCH_DB_STOP:
        case Actions.DB_READY:
            return {
                ...state,
                indexedDbReady: true
            }
         case Actions.DB_FAIL:
            return {
                ...state,
                indexedDbReady: false,
                indexedDbError: action.payload
            }
        case Actions.SET_RISK_DB_STATUS:
            return {
                ...state,
                indexedDbReady: true,
                indexedDbStatus: action.payload
            }*/
    default:
      return state;
  }
}
