import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, EMPTY, from, of } from 'rxjs';
import { DbService } from './db.service';
import { first, mergeMap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersResolverService implements Resolve<any>{

  constructor(private db: DbService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<any> | Observable<never> {
    return from(this.db.getTableData('smart-pga_users', 'user')).pipe(
      first(),
      mergeMap(users => of(users)),
      catchError(err => {
        console.error('UsersResolver error:', err);
        this.router.navigateByUrl('/auth');
        return EMPTY;
      })
    );
  }
}
