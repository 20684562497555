import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';

import { Event as NavigationEvent } from '@angular/router';
import { filter, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouterStateService {
  private isPopstate$ = new BehaviorSubject(false);

  constructor(router: Router) {
    router.events
      .pipe(
        filter((event: NavigationEvent) => event instanceof NavigationStart),
        tap((event: NavigationStart) => this.isPopstate$.next(event.navigationTrigger === 'popstate'))
      )
      .subscribe();
  }

  inAppNavigation(): boolean {
    return !this.isPopstate$.getValue();
  }
}
