import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { concatMap, mergeMap, tap, map, withLatestFrom, filter, catchError } from 'rxjs/operators';

import { RootStoreState } from '../';
import * as CameraActions from './actions';
import * as StationActions from '../station/actions';

import { CameraManagerService } from '../../services/camera-manager.service';
import { fromEvent, from } from 'rxjs';

import * as b64toBlob from 'b64-to-blob';

@Injectable()
export class CameraEffects {
  constructor(
    private action$: Actions,
    private store: Store<RootStoreState.State>,
    private cameraManager: CameraManagerService
  ) {}

  @Effect()
  startCamera$ = this.action$.pipe(
    ofType(CameraActions.START),
    withLatestFrom(this.store.select('camera')),
    filter(([action, cameraStore]) => !cameraStore.connecting),
    mergeMap(() => [{ type: CameraActions.CONNECTING }, { type: CameraActions.COMPILE_STREAM }])
  );

  @Effect()
  connectingCamera$ = this.action$.pipe(
    ofType(CameraActions.COMPILE_STREAM),
    concatMap(() =>
      from(this.cameraManager.connectCamera()).pipe(
        mergeMap(stream => [
          { type: CameraActions.SET_STREAMING_TRACK, payload: stream.getTracks()[0] },
          { type: CameraActions.START_STREAMING, payload: stream },
          { type: CameraActions.RESET_ERROR }
        ]),
        catchError(err => [
          { type: CameraActions.STOP },
          { type: CameraActions.SET_ERROR, payload: 'Camera not found. For continue operation use photo uploader.' }
        ])
      )
    )
  );

  @Effect()
  stopCamera$ = this.action$.pipe(
    ofType(CameraActions.STOP),
    withLatestFrom(this.store.select('camera')),
    tap(([action, cameraStore]) => {
      if (cameraStore.stream) {
        cameraStore.stream.stop();
      }
    }),
    tap(() => {
      const VIDEO_STREAM_HTML = document.getElementById('videoStream');

      if (VIDEO_STREAM_HTML) {
        VIDEO_STREAM_HTML.remove();
      }
    }),
    mergeMap(() => [
      { type: CameraActions.DEACTIVATE },
      { type: CameraActions.DELETE_STREAMING_TRACK },
      { type: CameraActions.DISCONNECTING },
      { type: CameraActions.STOP_RECORDING }
    ])
  );

  @Effect()
  startStreaming$ = this.action$.pipe(
    ofType(CameraActions.START_STREAMING),
    map(action => this.cameraManager.renderVideoStream(action['payload'])),
    mergeMap(videoElement => fromEvent(videoElement, 'loadedmetadata').pipe(tap(() => videoElement.play()))),
    mergeMap(videoEvent => [
      { type: CameraActions.STREAMING_SUCCESS },
      {
        type: CameraActions.SET_STREAM_DIMENSION,
        payload: { height: videoEvent.target['videoHeight'], width: videoEvent.target['videoWidth'] }
      },
      { type: CameraActions.ACTIVATE }
    ])
  );

  @Effect()
  makePhoto$ = this.action$.pipe(
    ofType(CameraActions.MAKE_PHOTO),
    mergeMap(() => [{ type: CameraActions.START_RECORDING }, { type: CameraActions.CAPTURE_SNAPSHOT }])
  );

  @Effect()
  captureSnapshot$ = this.action$.pipe(
    ofType(CameraActions.CAPTURE_SNAPSHOT),
    withLatestFrom(this.store.select('camera')),
    map(([action, cameraStore]) =>
      this.cameraManager.captureSnapshot(
        cameraStore.streamDimension['height'],
        cameraStore.streamDimension['width'],
        cameraStore.flip
      )
    ),
    mergeMap(base64 => {
      const contentType = 'image/jpg';
      const blob = b64toBlob.default(base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''), contentType);
      const urlBlob = window.URL.createObjectURL(blob);
      return [
        { type: CameraActions.STOP },
        { type: CameraActions.SET_PHOTO_BLOB_FILE, payload: blob },
        { type: CameraActions.SET_PHOTO_BLOB_URL, payload: urlBlob }
      ];
    })
  );
}
