import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { SystemActions } from 'src/app/root-store';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardCardComponent implements OnInit {
  @Input() operation: any;

  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();

  totalPhotos: any;

  associatedRequest: any;
  status = 'in work';

  constructor(private store: Store<any>) {}

  ngOnInit() {
    this.associatedRequest = this.operation['requests']
      ? this.operation['requests'].find(req => req.id === this.operation.request_id)
      : null;
    if (this.operation.step_finish !== undefined && this.operation.step_finish !== null ) {
      this.status = this.operation.forFetch ? 'pending' : 'uploaded';
    }

    this.totalPhotos = this.operation.total_photos? this.operation.total_photos : 0;
  }

  editOperation(operation): void {
    this.edit.next(operation);
  }

  deleteOperation(operation): void {
    const agree = confirm(
      `Vuoi eliminare intervento: ${operation.id_rete} | ${operation.id_stazione} -  ${operation.nome}?`
    );
    if (agree) {
      this.delete.next(operation);
    }
  }

  uploadOperation(operation): void {
    operation['uploading$'] = new BehaviorSubject(true);

    this.store.dispatch(new SystemActions.TryUploadOperation(operation));
  }
}
