import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { stationReducer } from './reducers';
import { StationEffects } from './effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('station', stationReducer),
    EffectsModule.forFeature([StationEffects])
  ],
  declarations: []
})
export class StationModule { }
