import { Injectable } from '@angular/core';
import * as idb from 'idb/lib/node';

import { prototypeDB } from '../mock/db.model';
import { Subject } from 'rxjs';
import { HashCodeService } from './hash-code.service';

@Injectable({
  providedIn: 'root'
})
export class DbService {
  dbPromise: any;
  version = 2;
  updater = new Subject();

  constructor(private hashCodeService: HashCodeService) {}

  /**
   * @description Initialize DB with Predefined Stores (tables)
   */
  createInstanceDB() {
    const DB_USERS = idb.open('smart-pga_users', this.version, upgradeDb => {
      // First App launch on device
      if (upgradeDb.oldVersion === 0) {
        upgradeDb.createObjectStore('user', { keyPath: ['username'] });
      }
    });

    const DB_STATIONS = idb.open('smart-pga_stations', this.version, upgradeDb => {
      // First App launch on device
      if (upgradeDb.oldVersion === 0) {
        upgradeDb.createObjectStore('station', { keyPath: ['id_cliente', 'id_stazione'] });
      }
    });

    const DB_NETWORKS = idb.open('smart-pga_networks', this.version, upgradeDb => {
      // First App launch on device
      if (upgradeDb.oldVersion === 0) {
        upgradeDb.createObjectStore('network', { keyPath: 'id', autoIncrement: true });
      }
    });

    const DB_TOOLS = idb.open('smart-pga_tools', this.version, upgradeDb => {
      // First App launch on device
      if (upgradeDb.oldVersion === 0) {
        upgradeDb.createObjectStore('tool', { keyPath: 'id', autoIncrement: true });
      }
    });

    const DB_TASKS = idb.open('smart-pga_tasks', this.version, upgradeDb => {
      // First App launch on device
      if (upgradeDb.oldVersion === 0) {
        upgradeDb.createObjectStore('task', { keyPath: 'id', autoIncrement: true });
      }
    });

    const DB_CALIBRATIONS = idb.open('smart-pga_calibrations', this.version, upgradeDb => {
      // First App launch on device
      if (upgradeDb.oldVersion === 0) {
        upgradeDb.createObjectStore('calibration', { keyPath: 'id', autoIncrement: true });
      }
    });

    const DB_DATASELECT = idb.open('smart-pga_dataselect', this.version, upgradeDb => {
      // First App launch on device
      if (upgradeDb.oldVersion === 0) {
        upgradeDb.createObjectStore('dataselect', { keyPath: 'id', autoIncrement: true });
      }
    });

    const DB_APP = idb.open('smart-pga', this.version, upgradeDb => {
      // First App launch on device
      if (upgradeDb.oldVersion === 0) {
      }

      // Delete unused rows from DB
      if (upgradeDb.version > upgradeDb.oldVersion && upgradeDb.oldVersion > 0) {
        // Get keys which should be deleted from DB
        const STORE_KEYS: Array<any> = [];
        const REAL_KEYS: Array<any> = [];

        for (let i = 0; i < upgradeDb.objectStoreNames.length; i++) {
          STORE_KEYS.push(upgradeDb.objectStoreNames[i]);
        }

        for (const key in prototypeDB) {
          REAL_KEYS.push(key);
        }

        const negative_keys = STORE_KEYS.filter(store_key => REAL_KEYS.every(key => store_key !== key));
        negative_keys.map(key => upgradeDb.deleteObjectStore(key));
      }

      // Populate Object Stores
      for (const key in prototypeDB) {
        // Delete old rows structure
        if (upgradeDb.oldVersion > 0 && upgradeDb.version > upgradeDb.oldVersion && upgradeDb.version === 1) {
          try{
            upgradeDb.deleteObjectStore(key);
          }catch{
            console.log('new table')
          }
        }

        if (!upgradeDb.objectStoreNames.contains(key)) {
          if (key === 'app_pending_files') {
            const store = upgradeDb.createObjectStore(key, { keyPath: 'id', autoIncrement: true });
            store.createIndex('id_by_server', 'id_by_server', { unique: false /*, multiEntry: false*/ });
          } else if (key === 'app_pending_errors') {
            upgradeDb.createObjectStore(key, { keyPath: 'id', autoIncrement: true });
          } else {
            upgradeDb.createObjectStore(key, {
              keyPath: ['id_cliente', 'id_stazione', 'date_index', 'operation_type']
            });
          }
        }
      }
    });

    const DB_SETTINGS = idb.open('smart-pga_settings', this.version, upgradeDb => {
      if (upgradeDb.oldVersion === 0) {
      }
    });

    const DB_DOCUMENTS_TYPES = idb.open('smart-pga_documentstypes', this.version, upgradeDb => {
      // First App launch on device
      if (upgradeDb.oldVersion === 0) {
        upgradeDb.createObjectStore('documentstypes', { keyPath: ['id'] });
      }
    });

    return Promise.all([
      DB_USERS,
      DB_STATIONS,
      DB_NETWORKS,
      DB_TOOLS,
      DB_TASKS,
      DB_CALIBRATIONS,
      DB_DATASELECT,
      DB_APP,
      DB_SETTINGS,
      DB_DOCUMENTS_TYPES
    ]);
  }

  async getJWT(): Promise<any> {
    const db = await idb.open('smart-pga_settings', this.version);

    const tx = db.transaction('auth', 'readonly');
    const store = tx.objectStore('auth');

    return store.getAll();
  }

  deleteJWT(): Promise<any> {
    return idb.open('smart-pga_settings', this.version).then(db => {
      const tx = db.transaction('auth', 'readwrite');
      const store = tx.objectStore('auth');

      store.clear();
      return tx.complete;
    });
  }

  setUsernameAndPassword(username?: string, password?: string): Promise<any> {
    const DATA = {
      id: 0,
      username: this.hashCodeService.convertIntoHashCode(username),
      password: this.hashCodeService.convertIntoHashCode(password)
    };

    return idb.open('smart-pga_settings', this.version).then(db => {
      const tx = db.transaction('account', 'readwrite');
      const store = tx.objectStore('account');
      
      store.put(DATA);
      return tx.complete;
    });
  }

  getUsernameAndPassword(): Promise<any> {
    return idb.open('smart-pga_settings', this.version).then(db => {
      const tx = db.transaction('account', 'readonly');
      const store = tx.objectStore('account');
      return store.getAll();
    });
  }

  setJWT(token?: string, username?: string): Promise<any> {
    const DATA = {
      id: 0,
      token: token,
      username: username
    };

    return idb.open('smart-pga_settings', this.version).then(db => {
      const tx = db.transaction('auth', 'readwrite');
      const store = tx.objectStore('auth');

      store.put(DATA);
      return tx.complete;
    });
  }

  /**
   * @description Find user in DB _user
   * @param user Object with data which typed in user
   */
  getUser(username: string): Promise<any> {
    return idb.open('smart-pga_users', this.version).then(db => {
      const tx = db.transaction('user', 'readonly');
      const store = tx.objectStore('user');
      return store.get([username]);
    });
  }

  getOperation(keys: Array<any>): Promise<any> {
    return idb.open('smart-pga', this.version).then(db => {
      const tx = db.transaction('app_operation', 'readonly');
      const store = tx.objectStore('app_operation');
      return store.get(keys);
    });
  }

  deleteOperation(keys: Array<any>): Promise<any> {
    return idb.open('smart-pga', this.version).then(db => {
      const tx = db.transaction('app_operation', 'readwrite');
      const store = tx.objectStore('app_operation');
      return store.delete(keys);
    });
  }

  getPendingOperation(keys: Array<any>): Promise<any> {
    return idb.open('smart-pga', this.version).then(db => {
      const tx = db.transaction('app_pending_operation', 'readonly');
      const store = tx.objectStore('app_pending_operation');
      return store.get(keys);
    });
  }

  getPendingError(keys: Array<any>): Promise<any> {
    return idb.open('smart-pga', this.version).then(db => {
      const tx = db.transaction('app_pending_errors', 'readonly');
      const store = tx.objectStore('app_pending_errors');
      return store.get(keys);
    });
  }

  addOperation(data: any): Promise<any> {
    return idb.open('smart-pga', this.version).then(db => {
      const tx = db.transaction('app_operation', 'readwrite');
      const store = tx.objectStore('app_operation');

      store.put(data);
      return tx.complete;
    });
  }

  addPendingOperation([_id_cliente, _id_stazione, _date_index, _operation_type]: Array<any>): Promise<any> {
    return idb.open('smart-pga', this.version).then(db => {
      const tx = db.transaction('app_pending_operation', 'readwrite');
      const store = tx.objectStore('app_pending_operation');

      store.put({
        id_cliente: _id_cliente,
        id_stazione: _id_stazione,
        date_index: _date_index,
        operation_type: _operation_type
      });

      return tx.complete;
    });
  }

  addPendingErrors(msg:string): Promise<any> {
    return idb.open('smart-pga', this.version).then(db => {
      const tx = db.transaction('app_pending_errors', 'readwrite');
      const store = tx.objectStore('app_pending_errors');

      store.put({value:msg});

      return tx.complete;
    });
  }

  deletePendingOperation(keys: Array<any>): Promise<any> {
    return idb.open('smart-pga', this.version).then(db => {
      const tx = db.transaction('app_pending_operation', 'readwrite');
      const store = tx.objectStore('app_pending_operation');
      return store.delete(keys);
    });
  }

  deletePendingErrors(keys: Array<any>): Promise<any> {
    return idb.open('smart-pga', this.version).then(db => {
      const tx = db.transaction('app_pending_errors', 'readwrite');
      const store = tx.objectStore('app_pending_errors');
      return store.delete(keys);
    });
  }

  populateStations(stations: Array<any>): Promise<any> {
    return idb.open('smart-pga_stations', this.version).then(db => {
      const tx = db.transaction('station', 'readwrite');
      const store = tx.objectStore('station');

      // Clear store
      store.clear();

      const ADDED_ITEM = stations.map(station => store.put(station));
      return Promise.all(ADDED_ITEM);
    });
  }

  async populateCollectionData(data: Array<any>, tableName: string, collectionName: string): Promise<any> {
    const db = await idb.open(tableName, this.version);
    const tx = db.transaction(collectionName, 'readwrite');
    const store = tx.objectStore(collectionName);

    // Clear store
    store.clear();
    if (Array.isArray(data)) {
      data.forEach(item => store.put(item));
    } else {
      // For DataSetSelectionList
      store.put(data);
    }
    
    return tx.complete;
  }

  getDataselect(): Promise<any> {
    return idb.open('smart-pga_dataselect', this.version).then(db => {
      const tx = db.transaction('dataselect', 'readonly');
      const store = tx.objectStore('dataselect');
      return store.getAll();
    });
  }

  async getTableData(dbName: string, nameTable: string, mode: 'readonly' | 'readwrite' = 'readonly'): Promise<any> {
    const db = await idb.open(dbName, this.version);
    const tx = db.transaction(nameTable, mode);
    const store = tx.objectStore(nameTable);

    const data = []
    let cursor = await store.openCursor();
    while (cursor) {
      data.push(cursor.value);
      cursor = await cursor.continue();
    }
    return Promise.resolve(data);
  }

  deleteRequest(keys: Array<any>, requestId: number): Promise<any> {
    return idb.open('smart-pga_stations', this.version).then(db => {
      const tx = db.transaction('station', 'readwrite');
      const store = tx.objectStore('station');

      return store.get(keys).then(station => {
        const requestsIndex = station.requests.findIndex(req => req.id === requestId);
        const requestsArr = [...station.requests];
        requestsArr.splice(requestsIndex, 1);

        station['requests'] = requestsArr.length ? [...requestsArr] : null;
        return store.put(station);
      });
    });
  }

  insertNotification(keys: Array<any>, status: string): Promise<any> {
    return idb.open('smart-pga', this.version).then(db => {
      const tx = db.transaction('app_operation', 'readwrite');
      const store = tx.objectStore('app_operation');

      return store.get(keys).then(item => {
        const UPDATED_ITEM = { ...item, notification_open_status: status };
        return store.put(UPDATED_ITEM);
      });
    });
  }

  async insertDataToDB(keys: Array<any>, data: any, stepName?: string): Promise<any> {
    const db = await idb.open('smart-pga', this.version);

    const tx = db.transaction('app_operation', 'readwrite');
    const store = tx.objectStore('app_operation');

    const item = await store.get(keys);
    const UPDATED_ITEM = { ...item, ...data };

    if (stepName) {
      const photos = { ...item.photos };
      photos[stepName] = data.photos;
      UPDATED_ITEM['photos'] = { ...photos };
      UPDATED_ITEM['total_photos'] = this.countPhotos(UPDATED_ITEM['photos']);
    }

    return store.put(UPDATED_ITEM);
  }

  insertTasksDraft(keys: Array<any>, tasks: any): Promise<any> {
    return idb.open('smart-pga', this.version).then(db => {
      const tx = db.transaction('app_operation', 'readwrite');
      const store = tx.objectStore('app_operation');
      return store.get(keys).then(item => {
        item['step_tasks'] = tasks['step_tasks'];
        return store.put(item);
      });
    });
  }

  insertWarehouse(keys: Array<any>, tasks: any): Promise<any> {
    return idb.open('smart-pga', this.version).then(db => {
      const tx = db.transaction('app_operation', 'readwrite');
      const store = tx.objectStore('app_operation');
      return store.get(keys).then(item => {
        item['movementsStore'] = tasks['movementsStore'];
        return store.put(item);
      });
    });
  }

  addPendingFile(file, idOperation): Promise<any> {
    return idb.open('smart-pga', this.version).then(db => {
      const tx = db.transaction('app_pending_files', 'readwrite');
      const store = tx.objectStore('app_pending_files');

      const FILE = {
        ...file,
        id_by_server: idOperation
      };

      store.put(FILE);
      return tx.complete;
    });
  }

  deletePendingFile(id: number): Promise<any> {
    return idb.open('smart-pga', this.version).then(db => {
      const tx = db.transaction('app_pending_files', 'readwrite');
      const store = tx.objectStore('app_pending_files');
      return store.delete(id);
    });
  }

  getCurrenVersion(): Promise<any> {
    return idb.open('smart-pga_settings', this.version).then(db => {
      const tx = db.transaction('version', 'readonly');
      const store = tx.objectStore('version');
      return store.getAll().then(item => {
        if (item.length > 0) {
          return Promise.resolve(item[0].version_db);
        }
        return Promise.reject(false);
      });
    });
  }

  setVersion(version: number): Promise<any> {
    return idb.open('smart-pga_settings', this.version).then(db => {
      const tx = db.transaction('version', 'readwrite');
      const store = tx.objectStore('version');

      store.clear();
      return store.put({ version_db: version });
    });
  }

  async isDbInstalled(): Promise<any> {
    const db = await idb.open('smart-pga_settings', this.version, upgradeDb => {
      if (upgradeDb.oldVersion === 0) {
        upgradeDb.createObjectStore('version', { keyPath: 'version_db', autoIncrement: true });
        upgradeDb.createObjectStore('auth', { keyPath: 'id', autoIncrement: false });
        upgradeDb.createObjectStore('account', { keyPath: 'id', autoIncrement: false });
      }
    });

    const tx = db.transaction('version', 'readonly');
    const store = tx.objectStore('version');

    const items = await store.getAll();
    return Promise.resolve(db.objectStoreNames.length > 0 && items.length > 0);
  }

  onDbUpdate(): Subject<any> {
    return this.updater;
  }

  updateDbInViews() {
    this.updater.next();
  }

  updateStation(station: any): Promise<any>{
    console.info('updating station', station)
    
    return idb.open('smart-pga_stations', this.version).then(db => {
      const tx = db.transaction('station', 'readwrite');
      const store = tx.objectStore('station');
      
      return store.put(station);
    });
  }

  private countPhotos(photos: any): number {
    const totalPhotos = Object.values(photos).reduce((total: number, array) => {
      if (Array.isArray(array)) {
        total += array.length;
      }
      return total;
    }, 0) as number;

    return totalPhotos;
  }
}
