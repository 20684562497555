import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from './../environments/environment';

import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import { ContainerComponent } from './container/container.component';
import { RootStoreModule } from './root-store/root-store.module';
import { AuthComponent } from './auth/auth.component';
import { RoutingModule } from './routing/routing.module';
import { DashboardComponent } from './container/dashboard/dashboard.component';
import { DashboardTableComponent } from './container/dashboard/dashboard-table/dashboard-table.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ByGPSCoordPipe } from './by-gpscoord.pipe';
import { jwtProvider } from './services/interceptor.service';
import { GuestComponent } from './guest/guest.component';
import { AuthDialogComponent } from './dialogs/auth-dialog/auth-dialog.component';
import { TasksService } from './services/tasks.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DashboardCardComponent } from './container/dashboard/dashboard-card/dashboard-card.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { RequestsListComponent } from './container/requests-list/requests-list.component';
import { DocumentsListComponent } from './container/documents-list/documents-list.component';
import { SharedModule } from './shared.module';
import { DatePipe } from '@angular/common';
import { GlobalErrorHandlerService } from './services/global-error-handler.service';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    ContainerComponent,
    AuthComponent,
    DashboardComponent,
    DashboardTableComponent,
    SidebarComponent,
    ByGPSCoordPipe,
    GuestComponent,
    AuthDialogComponent,
    DashboardCardComponent,
    RequestsListComponent,
    DocumentsListComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RootStoreModule,
    RoutingModule,
    HttpClientModule,
    SharedModule,
    !environment.production
      ? StoreDevtoolsModule.instrument({
        maxAge: 15
      })
      : [],

    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }) // { enabled: environment.production }
  ],
  entryComponents: [AuthDialogComponent],
  providers: [jwtProvider, TasksService, DatePipe, { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: ErrorHandler, 
      useClass: GlobalErrorHandlerService
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
