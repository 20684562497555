
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialsModule } from './materials/materials.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RequestCardComponent } from './container/request-card/request-card.component';
import { LoaderComponent } from './components/loader/loader.component';
import { CameraDialogComponent } from './container/photo-camera/camera-dialog/camera-dialog.component';
import { AddPhotoButtonComponent } from './components/add-photo-button/add-photo-button.component';
import { PhotoCameraComponent } from './container/photo-camera/photo-camera.component';
import { BlobImageComponent } from './components/blob-image/blob-image.component';
import { ImagesUploadedBufferComponent } from './components/images-uploaded-buffer/images-uploaded-buffer.component';

/**
 * Include AngularMaterial module, and components which used in different modules.
 *
 * @export
 * @class SharedModule
 */
@NgModule({
  declarations: [RequestCardComponent,
    LoaderComponent,
    PhotoCameraComponent,
    AddPhotoButtonComponent,
    CameraDialogComponent,
    BlobImageComponent,
    ImagesUploadedBufferComponent
  ],
  imports: [
    CommonModule,
    MaterialsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  entryComponents: [
    CameraDialogComponent
  ],
  exports: [
    MaterialsModule,
    FormsModule,
    ReactiveFormsModule,
    RequestCardComponent,
    LoaderComponent,
    AddPhotoButtonComponent,
    CameraDialogComponent,
    PhotoCameraComponent,
    BlobImageComponent,
    ImagesUploadedBufferComponent
  ]
})
export class SharedModule {}
