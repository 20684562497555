export interface UserCredentials {
    username: string;    
    descr: string;    
    role: string;
    id_operatore: number;
    mail: string;
}
export interface AuthState {
    guestMode: boolean;
    isAuthenticated: boolean;
    credentials: UserCredentials;
    authenticating: boolean;
    jwt: Object;
    errorMessage: string;
}

/* roles:
    @ - Admin
    ! - User    
*/ 
export const initialState: AuthState = {
    guestMode: false,
    isAuthenticated: false,
    authenticating: false,
    jwt: {},
    credentials: {
        username: null,
        descr: null,
        role: '@',
        id_operatore: null,
        mail: null
    },
    errorMessage: null
};
