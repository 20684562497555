import { Injectable } from '@angular/core';
import { Observable, fromEvent, merge } from 'rxjs';
import { map, first, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ModalManagerService {

  constructor() { }

  openOverwriteModal():Observable<boolean> {
    const modalBox = document.createElement('div');
    modalBox.id = "modal";

    const modalContainer = document.createElement('div');
    modalContainer.className = "modal__container";
    
    const modalDescription = document.createElement('p');
    modalDescription.innerHTML = 'Operation already exist. Would you overwrite it?';

    const modalButtonContainer = document.createElement('div');
    modalButtonContainer.className = "modal__button__container";

    const modalButtonYes = document.createElement('a');
    modalButtonYes.id = "buttonYes";
    modalButtonYes.className = 'modal__button';
    modalButtonYes.innerHTML = 'Overwrite';
    
    const modalButtonNo = document.createElement('a');
    modalButtonNo.id = "buttonNo";
    modalButtonNo.className = 'modal__button';
    modalButtonNo.innerHTML = 'Go back';

    modalBox.appendChild(modalContainer);

    modalContainer.appendChild(modalDescription);
    modalContainer.appendChild(modalButtonContainer);

    modalButtonContainer.appendChild(modalButtonYes);
    modalButtonContainer.appendChild(modalButtonNo);

    document.body.appendChild(modalBox);

    const buttonYes$ = fromEvent(modalButtonYes, 'click').pipe(
      map(()=> true)
    );
    
    const buttonNo$ = fromEvent(modalButtonNo, 'click').pipe(
      map(()=> false)
    );
    
    const clicks$ = merge(buttonYes$, buttonNo$)
      .pipe(
        first(),
        tap(()=>modalBox.remove())
      );

    return clicks$;
  }
}
