import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthAction, RootStoreState } from '../root-store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.css']
})
export class GuestComponent implements OnInit {
  selectedUser: any;
  users: any;
  constructor(private route: ActivatedRoute, private store: Store<RootStoreState.State>) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: {users: any}) => this.users =  data.users);
  }

  openApp(): void {
    this.store.dispatch(new AuthAction.GuestSelected(this.selectedUser));
  }
}
