import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';

import { CameraEffects } from './effects';
import { cameraReducer } from './reducers';

import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('camera', cameraReducer),
    EffectsModule.forFeature([CameraEffects])
  ],
  declarations: []
})
export class CameraModule { }
