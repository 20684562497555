import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { DbActions, RootStoreState, SystemActions } from '../root-store';
import { StationAction } from '../root-store/station';
import { SystemManagerService } from '../services/system-manager.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.css']
})
export class ContainerComponent implements OnInit {
  authState: Observable<any>;
  systemState: Observable<any> = this.store.select('system');
  sidebarOpen$ = this.systemState.pipe(map(({ sidebar }) => sidebar));
  dbState: Observable<any>;

  constructor(private store: Store<RootStoreState.State>, private systemManager: SystemManagerService) {}

  ngOnInit() {
    this.store.dispatch(new DbActions.InitDb())
    this.startMonitoringConnection();
    this.laucnhSyncWithIndexeDB();

    // Export state to components [Navigation]
    this.authState = this.store.select('auth');
    this.dbState = this.store.select('db');
  }

  /**
   * @description Launch monitoring internet connection
   */
  startMonitoringConnection(): void {
    const onlineHandler = () => {
      this.store.dispatch(new SystemActions.TryUploadPendingOperations());
      this.store.dispatch(new SystemActions.TryUploadSavedFiles());
      this.store.dispatch(new SystemActions.TryUploadPendingErrors());
      this.store.dispatch(new SystemActions.Online());
    };

    const offlineHandler = () => this.store.dispatch(new SystemActions.Offline());

    this.systemManager.connectionStatusMonitoring(onlineHandler, offlineHandler);
  }

  /**
   * @description Launch sychronisation with IndexedDB
   */
  laucnhSyncWithIndexeDB(): void {
    // Put stations to store
    this.store.dispatch(new StationAction.SyncStations());
    this.store.dispatch(new StationAction.SyncNetworks());
  }
}
