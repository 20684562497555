import { Action } from '@ngrx/store';

export const TRY_SIGN_IN_LAUNCH = '[Auth page] Try Sign in and Launch App';
export const TRY_SIGN_IN_INSTALL = '[Auth page] Try Sign in and Install App';
export const TRY_SIGN_IN_GUEST = '[Auth page] Try Sign in in guest mode';
export const TRY_AUTHENTICATE_IN_APP = '[Auth dialog] Try authenticate in app';
export const AUTHENTICATE_IN_APP_FAILED = '[Auth dialog] Authenticate in app failed';


export const SIGN_IN = 'SIGN_IN';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const GUEST_SELECTED = '[Guest page] Guest selected';
export const SET_USER_CREDENTIALS = '[Auth] Set user credentials';
export const SIGN_IN_FAILED = 'SIGN_IN_FAILED';
export const SIGN_IN_GUEST_SUCCESS = '[Auth] Sign in in Guest Mode success';


export const CHECK_AUTH_STATE_BOOTSTRAP = '[App Component] Check if user already authenticated on bootstrap';
export const FIND_USER_CREDENTIALS = '[IndexedDB] Find authenticated users credentials in local DB';

export const SET_JWT_TOKEN = '[IndexedDB] Set JWT token to DB';
export const SET_JWT_TOKEN_SUCCESS = '[IndexedDB] Set JWT token to DB success';

export const SET_GUEST_MODE = '[Auth] Set guest mode';
export const RESET_GUEST_MODE = '[Auth] Reset guest mode';

export const SET_JWT_TOKEN_TEMPORARY = '[Auth state] Set temporary JWT token';
export const DELETE_JWT_TOKEN_TEMPORARY = '[Auth state] Delete temporary JWT token';

export const DELETE_JWT_TOKEN = '[IndexedDB] JWT token delete from DB';
export const DELETE_JWT_TOKEN_SUCCESS = '[IndexedDB] JWT token delete from DB success';

export const START_AUTH = '[API Auth] Start auth data on server';
export const STOP_AUTH = '[API Auth] Stop auth data on server';

export const LOGOUT = '[Navigation bar] Click logout';
export const DB_ERROR = '[Auth Error]';

export const SET_USERNAME_AND_PASSWORD = '[Auth] Set username and password';

export class CheckAuthStateBootstrap implements Action {
    readonly type = CHECK_AUTH_STATE_BOOTSTRAP;
}

export class SignIn implements Action {
    readonly type = SIGN_IN;
}

export class AuthSuccess implements Action {
    readonly type = AUTH_SUCCESS;
}

export class GuestSelected implements Action {
    readonly type = GUEST_SELECTED;
    constructor (public payload: any) {}
}

export class SetUserCredentials implements Action {
    readonly type = SET_USER_CREDENTIALS;
    constructor (public payload: any) {}
}

export class SignInFailed implements Action {
    readonly type = SIGN_IN_FAILED;
    constructor (public payload: string){}
}

export class AuthenticateInAppFailed implements Action {
    readonly type = AUTHENTICATE_IN_APP_FAILED;
    constructor (public payload: string){}
}



export class Logout implements Action {
    readonly type = LOGOUT;
}

export class StartAuth implements Action {
    readonly type = START_AUTH;
}

export class StopAuth implements Action {
    readonly type = STOP_AUTH;
}

export class TrySignInLaunch implements Action {
    readonly type = TRY_SIGN_IN_LAUNCH;
    constructor (public payload: {username: string, password: string}) {}
}

export class TrySignInInstall implements Action {
    readonly type = TRY_SIGN_IN_INSTALL;
    constructor (public payload: {username: string, password: string}) {}
}

export class TrySignInGuest implements Action {
    readonly type = TRY_SIGN_IN_GUEST;
}

export class TryAuthenticate implements Action {
    readonly type = TRY_AUTHENTICATE_IN_APP;
    constructor (public payload:any) {}
}

export class SetJwtTokenTemporary implements Action {
    readonly type = SET_JWT_TOKEN_TEMPORARY;
    constructor (public payload: Object){}
}

export class SetGuestMode implements Action {
    readonly type = SET_GUEST_MODE;
}

export class ResetGuestMode implements Action {
    readonly type = RESET_GUEST_MODE;
}

export class SetUsernameAndPassword implements Action {
    readonly type = SET_USERNAME_AND_PASSWORD;
    constructor (public payload: Object){}
}
export type AuthActions = CheckAuthStateBootstrap | SignIn | AuthSuccess 
    | SetUserCredentials |  SetJwtTokenTemporary | SetGuestMode | ResetGuestMode
    | TrySignInLaunch | TrySignInInstall | TrySignInGuest | TryAuthenticate 
    | SignInFailed | AuthenticateInAppFailed | StartAuth | StopAuth | Logout | SetUsernameAndPassword;