import { Component, OnInit } from '@angular/core';
import { DbService } from '../../services/db.service';

import { from, Observable, Subscription, BehaviorSubject } from 'rxjs';
import { map, mergeMap, concatMap, reduce, catchError, tap } from 'rxjs/operators';
import { RootStoreState, SystemActions } from '../../root-store';
import { Store } from '@ngrx/store';
import { StepActions } from '../../root-store/step';
import { StationAction } from '../../root-store/station';
import { ApiService } from 'src/app/services/api.service';

/**
 * Display started operation, finished operation, uploaded operation
 *
 * @export
 * @class DashboardComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  pendingOperations: Array<any> = [];
  authState: Observable<any>;
  updaterSubscription: Subscription;
  allOperations$: Observable<any>;
  
  displayedColumns: string[] = [
    'date_start',
    'operation_type',
    'id_rete',
    'nome',
    'username',
    'notification_open_status',
    'data',
    'forFetch',
    'controls'
  ];
  hideUploaded: Boolean = true;
  loading: Boolean = false;

  constructor(
    private db: DbService,
    private api: ApiService,
    private store: Store<RootStoreState.State>) {}

  ngOnInit() {
    this.drawTable();

    // Reset State Step
    this.store.dispatch(new StepActions.ResetState());

    // Reset State Station
    this.store.dispatch(new StationAction.ResetData());

    this.authState = this.store.select('auth');

    // Updater DB
    this.updaterSubscription = this.db.onDbUpdate().subscribe(() => this.drawTable());
  }

  drawTable() {
    // Gett All Operations from DB
    this.loading = true;
    const operations$ = from(this.db.getTableData('smart-pga', 'app_operation'));

    // Make check for fetch state of operation and accumulate in array
    this.allOperations$ = operations$.pipe(
      mergeMap(operations =>
        from(operations).pipe(
          concatMap(operation =>
            from(this.db.getPendingOperation(operation['id'])).pipe(
              map(pendingOperation => {
                operation['forFetch'] = !!pendingOperation;
                return operation;
              })
            )
          )
        )
      ),
      reduce((acc, operation) => {
        acc.push(operation);
        return acc;
      }, []),
      tap(() => (this.loading = false)),
      catchError(err => {
        this.loading = false;
        const errMsg = `Dashboard.component.ts: ${err.toString()}`;
        console.error(err);
        return this.api.sendError(errMsg);
      })
    ) as Observable<any>;
  }

  uploadPendingOperation() {
    this.store.dispatch(new SystemActions.TryUploadPendingOperations());
    this.store.dispatch(new SystemActions.TryUploadSavedFiles());
    this.drawTable();
  }
}
