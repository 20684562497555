import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { RootStoreState } from 'src/app/root-store';
import { Store } from '@ngrx/store';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { CameraAction } from 'src/app/root-store/camera';
@Component({
  selector: 'app-camera-dialog',
  templateUrl: './camera-dialog.component.html',
  styleUrls: ['./camera-dialog.component.css']
})
export class CameraDialogComponent {
  blobUrl: string;
  fileBlob: any;
  imgUrl: SafeUrl;
  multiFiles: Array<any>;

  cameraState: Observable<any> = this.store.select('camera');
  cameraUrl$ = this.cameraState.pipe(
    map(state => state.urlBlob),
    distinctUntilChanged()
  );
  constructor(
    private store: Store<RootStoreState.State>,
    public dialogRef: MatDialogRef<CameraDialogComponent>,
    private sanitizer: DomSanitizer
  ) {}

  addPhoto(): void {
    this.dialogRef.close(this.fileBlob);
  }

  deletePhoto(): void {
    this.blobUrl = null;
    this.imgUrl = null;
  }

  getBlobUrl(url: any): void {
    this.blobUrl = url;
    this.imgUrl = this.sanitizer.bypassSecurityTrustUrl(url);
    this.store.dispatch(new CameraAction.SetPhotoBlob(url));
  }

  getBlobFile(file: any): void {
    this.fileBlob = file;
  }

  getFiles(files: any): void {
    this.dialogRef.close(files);
  }
}
