import { DbState, DbActions } from './';

export function dbReducer(state = DbState.initialState, action:DbActions.DbActions) {
    switch (action.type) {
        case DbActions.SET_DB_INSTALL_STATUS:
            return {
                ... state,
                installed: action.payload
            };
        //case DbActions.SET_CURRENT_DB_VERSION:
        case DbActions.UPDATE_DB_VERSION:
            return {
                ...state,
                version: action.payload ? action.payload : null
            };
        case DbActions.RESET_DB_ERROR:
            return {
                ...state,
                error: null,
                ready: true,
                state: 'starting'
            };
        case DbActions.CONTROL_VERSION_FAIL:
            return {
                ...state,
                failedUpgrade: true
            };
        case DbActions.CONTROL_VERSION_SUCCESS:
            return {
                ...state,
                failedUpgrade: false
            };
        case DbActions.ERROR:
            return {
                ...state,
                status: null,
                ready: false
            };       
        case DbActions.INIT_DB:           
            return {
                ...state,
                ready: false,
                status: 'installing'
            }
        case DbActions.RESET_STATE:           
            return {
                ...DbState.initialState,
                installed: true
            }
        default:
            return {
                ... state
            };
    }
}